import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { StaffListComponent } from './staff-list/staff-list.component';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProductComponent } from './product/product.component';
import { ClientComponent } from './client/client.component';
import { ReportsComponent } from './reports/reports.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthGuard } from './auth.guard';
import { StaffDashboardComponent } from './staff-dashboard/staff-dashboard.component';
import { WorksComponent } from './works/works.component';
import { WorksPageComponent } from './works-page/works-page.component';
import { AddWorkPageComponent } from './add-work-page/add-work-page.component';
import { DisabledWorkPageComponent } from './disabled-work-page/disabled-work-page.component';
import { DynamicFieldCrudComponent } from './dynamic-field-crud/dynamic-field-crud.component';
import { RejectedFilesComponent } from './rejected-files/rejected-files.component';
import { ReminderFilesComponent } from './reminder-files/reminder-files.component';
import { DraftFilesComponent } from './draft-files/draft-files.component';
import { SwichUsertypeComponent } from './swich-usertype/swich-usertype.component';
import { ClientEnrolledComponent } from './client-enrolled/client-enrolled.component';
import { MailSentReminderComponent } from './mail-sent-reminder/mail-sent-reminder.component';
import { QuotationSentReminderComponent } from './quotation-sent-reminder/quotation-sent-reminder.component';
import { ViewWorkComponent } from './view-work/view-work.component';
const routes: Routes = [ { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'switch-usertype', component: SwichUsertypeComponent },

  // { path: 'agency', component: AgencyComponent },

  { path: '' , component: DashboardComponent , children: [
    { path: 'staff-list', component: StaffListComponent,canActivate: [AuthGuard], data: { role: 'Client Admin' } },  
    { path: 'product-list', component: ProductComponent ,canActivate: [AuthGuard], data: { role: 'Client Admin' }  },
    { path: 'client-list', component: ClientComponent,canActivate: [AuthGuard], data: { role: 'Client Admin' }   },
    { path: 'report', component: ReportsComponent ,canActivate: [AuthGuard], data: { role: 'Client Admin' }  },




    { path: 'main-dashboard', component: MainDashboardComponent  ,canActivate: [AuthGuard], data: { role: 'Client Admin' } },
    { path: 'works', component: WorksComponent,canActivate: [AuthGuard] },  
   
    { path: 'work-page', component: WorksPageComponent },  
    { path: 'add-work-page', component: AddWorkPageComponent  ,canActivate: [AuthGuard] },
    { path: 'dynamic-field', component: DynamicFieldCrudComponent  ,canActivate: [AuthGuard] },
    { path: 'rejected-page', component: RejectedFilesComponent  ,canActivate: [AuthGuard] },
    { path: 'reminder-page', component: ReminderFilesComponent  ,canActivate: [AuthGuard] },
    { path: 'client-enrolled', component: ClientEnrolledComponent   },

    { path: 'mail-send', component: MailSentReminderComponent   },
    { path: 'quotation-send', component: QuotationSentReminderComponent   },
    { 
      path: 'profile', 
      component: ProfileComponent, 
      canActivate: [AuthGuard]
      
    },
    { 
      path: 'disable-page', 
      component: DisabledWorkPageComponent, 
      canActivate: [AuthGuard]
      
    },
    { 
      path: 'draft-page', 
      component: DraftFilesComponent, 
      canActivate: [AuthGuard]
      
    },
    { 
      path: 'view-work', 
      component: ViewWorkComponent, 
      canActivate: [AuthGuard]
      
    },

  ]},
  // { path: '' , component: StaffDashboardComponent , children: [
  //   { path: 'works', component: WorksComponent},  
   
  //   { path: 'work-page', component: WorksPageComponent },  

  //   { 
  //     path: 'profile', 
  //     component: ProfileComponent, 
     
      
  //   },
    

  //   { path: 'staff-dashboard', component: StaffDashboardComponent  },
  //   { path: 'add-work-page', component: AddWorkPageComponent   },
    // { path: 'client-enrolled', component: ClientEnrolledComponent   },
  





  // ]}















];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
