import { NgModule , CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import {} from '@types/googlemaps';
import { FilterPipe } from '../pipes/filter.pipe'; 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InterceptorInterceptor } from './interceptor.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { StaffListComponent } from './staff-list/staff-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProductComponent } from './product/product.component';
import { AgmCoreModule } from '@agm/core';
import { AppConfig } from './config/config';
import { ClientComponent } from './client/client.component';
import { ReportsComponent } from './reports/reports.component';
import { ProfileComponent } from './profile/profile.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { StaffDashboardComponent } from './staff-dashboard/staff-dashboard.component';
import { StaffSidebarComponent } from './staff-sidebar/staff-sidebar.component';
import { WorksComponent } from './works/works.component';
import { WorksPageComponent } from './works-page/works-page.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AddWorkPageComponent } from './add-work-page/add-work-page.component';
import { DisabledWorkPageComponent } from './disabled-work-page/disabled-work-page.component';

import { Ng2TelInputModule } from 'ng2-tel-input';
import { DynamicFieldCrudComponent } from './dynamic-field-crud/dynamic-field-crud.component';
import { RejectedFilesComponent } from './rejected-files/rejected-files.component';
import { ReminderFilesComponent } from './reminder-files/reminder-files.component';
import { DraftFilesComponent } from './draft-files/draft-files.component';
import { SwichUsertypeComponent } from './swich-usertype/swich-usertype.component';
import { ClientEnrolledComponent } from './client-enrolled/client-enrolled.component';
import { QuotationSentReminderComponent } from './quotation-sent-reminder/quotation-sent-reminder.component';
import { MailSentReminderComponent } from './mail-sent-reminder/mail-sent-reminder.component';
import { ViewWorkComponent } from './view-work/view-work.component';
 


@NgModule({
  declarations: [

    AppComponent,
    LoginComponent,
    SidebarComponent,
    MainDashboardComponent,
    StaffListComponent,
    DashboardComponent,
    ProductComponent,
    ClientComponent,
    ReportsComponent,
    ProfileComponent,
    FilterPipe,
    StaffDashboardComponent,
    StaffSidebarComponent,
    WorksComponent,
    WorksPageComponent,
    AddWorkPageComponent,
    DisabledWorkPageComponent,
    DynamicFieldCrudComponent,
    RejectedFilesComponent,
    ReminderFilesComponent,
    DraftFilesComponent,
    SwichUsertypeComponent,
    ClientEnrolledComponent,
    QuotationSentReminderComponent,
    MailSentReminderComponent,
    ViewWorkComponent
  ],
  imports: [
    BrowserModule,
    NgxPaginationModule,
    Ng2TelInputModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: AppConfig.GOOGLE_API_KEY,
      libraries: ['places']
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA],

  providers: [  {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorInterceptor,
    multi: true // This allows you to use multiple interceptors
  }],
  bootstrap: [AppComponent],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],

})
export class AppModule { }
