import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable ,catchError,throwError} from 'rxjs';

@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {
  constructor( private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to modify it
    const modifiedReq = req.clone({ headers: req.headers.set('Accept', 'application/json') });

    // Pass the modified request to the next handler
  // Pass the modified request to the next handler
  return next.handle(modifiedReq).pipe(
    catchError((error: HttpErrorResponse) => {
      // Handle errors globally
      console.error('HTTP Error:', error);

      if (error.status === 401) {
        // Handle unauthorized errors (example)
        alert('Unauthorized! Please log in again.');
        this.router.navigate(["/login"])
      } else if (error.status === 500) {
        // Handle server errors
        // alert('A server error occurred. Please try again later.');
      } else {
        // Handle other types of errors
        // alert(`An error occurred: ${error.message}`);
      }

      return throwError(error); // Rethrow the error for further handling if needed
    })
  );  }
}
