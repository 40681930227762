import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to modify it
    const modifiedReq = req.clone({ headers: req.headers.set('Accept', 'application/json') });

    // Pass the modified request to the next handler
    return next.handle(modifiedReq);
  }
}
