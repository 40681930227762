import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { StaffListComponent } from './staff-list/staff-list.component';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProductComponent } from './product/product.component';
import { ClientComponent } from './client/client.component';
import { ReportsComponent } from './reports/reports.component';
import { ProfileComponent } from './profile/profile.component';
const routes: Routes = [ { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  // { path: 'agency', component: AgencyComponent },

  { path: '' , component: DashboardComponent , children: [
    { path: 'staff-list', component: StaffListComponent   },
    { path: 'product-list', component: ProductComponent   },
    { path: 'client-list', component: ClientComponent   },
    { path: 'report', component: ReportsComponent   },
    { path: 'profile', component: ProfileComponent   },




    { path: 'main-dashboard', component: MainDashboardComponent   },


  ]}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
