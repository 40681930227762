import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import moment from 'moment';
@Component({
  selector: 'app-mail-sent-reminder',
  templateUrl: './mail-sent-reminder.component.html',
  styleUrls: ['./mail-sent-reminder.component.css']
})
export class MailSentReminderComponent implements OnInit {

    currentPage: number = 1;
     AllocationFlag:any=false
     itemsPerPage: number = 25;
     totalItems: number =0
     tableSize: number[] = [5, 10, 20, 50, 100];
     pagingConfig: any = {}
     pageNo: any;
     size: any;
     searchQuery:any=''
     reminderCases:any=[]
     currentCount:any=0
     total:any=0
     token:any
     storedData:any
     sortOrder: 'asc' | 'desc' = 'asc';
     sortField: string = ''; // Tra
     currentSortColumn:any=''
     staffDetails:any=[]
     constructor(private loginService:LoginService,private router:Router) { this.pagingConfig = {
       itemsPerPage: this.itemsPerPage,
       currentPage: this.currentPage,
       totalItems: this.totalItems
     } }
   
     ngOnInit(): void {
       this.token = localStorage.getItem('token');
       this.storedData= JSON.parse(localStorage.getItem("user") || '{}');
      //  this.listStaffs()
      this.listReminderCases()
     }
     onTableSizeChange(event: any): void {
       this.pagingConfig.itemsPerPage = event.target.value;
       this.pagingConfig.currentPage = 1;
       this.size = event.target.value
       this.listReminderCases()
   
     }
     onTableDataChange(event: any) {
       this.pagingConfig.currentPage = event;
       this.listReminderCases()
     }
     listReminderCases(){
       
   
       let req={
         searchKey:this.searchQuery,
   
         page:this.pagingConfig.currentPage,
         limit:this.pagingConfig.itemsPerPage
       }
   
   
       let body={
         
         userType:this.storedData.userType[0].userType,
   
       }
   
   
         this.loginService.listReminderCases(this.token,req,body)
                   .subscribe((response: any) => {
                     if (response.status === 1) {
                            this.reminderCases=response.data
                            this.pagingConfig.totalItems =response.totalCount;
                            this.currentCount=response.data.length
                           this.total=response.totalCount
                     }})
     }
     ClientDetail(flag:any,obj:any){
       obj.flag=flag
       localStorage.setItem('Work',JSON.stringify(obj))
       this.router.navigate(["/add-work-page"])
   
     }


     format(date:any){
      return moment(date).format(`DD-MM-YYYY \n \nHH:mma`);
     
     }



     sortTable(column: string): void {
      this.sortField=column
       if (this.currentSortColumn === column) {
         // Toggle the sort order if the column is already sorted
         this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
       } else {
         // Set the column to the current sort column and default to ascending
         this.currentSortColumn = column;
         this.sortOrder = 'asc';
       }
     
       this.reminderCases.sort((a: any, b: any) => {
         let valueA = this.getValueByPath(a, column);
         let valueB = this.getValueByPath(b, column);
     
         // Handle null or undefined values
         if (valueA == null) return this.sortOrder === 'asc' ? 1 : -1;
         if (valueB == null) return this.sortOrder === 'asc' ? -1 : 1;
     
         // Attempt to parse dates
         const dateA = this.parseCustomDate(valueA);
         const dateB = this.parseCustomDate(valueB);
     
         if (dateA && dateB) {
           // Compare as dates
           if (dateA < dateB) return this.sortOrder === 'asc' ? -1 : 1;
           if (dateA > dateB) return this.sortOrder === 'asc' ? 1 : -1;
           return 0;
         }
     
         // Compare as numbers if both are numeric
         if (!isNaN(valueA) && !isNaN(valueB)) {
           valueA = Number(valueA);
           valueB = Number(valueB);
           return this.sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
         }
     
         // Compare as strings (case insensitive)
         valueA = String(valueA).toLowerCase();
         valueB = String(valueB).toLowerCase();
         if (valueA < valueB) return this.sortOrder === 'asc' ? -1 : 1;
         if (valueA > valueB) return this.sortOrder === 'asc' ? 1 : -1;
         return 0;
       });
     }

     parseCustomDate(dateString: string): Date | null {
      // Match DD/MM/YYYY
      const ddmmyyyyRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
      const ddmmyyyyMatch = dateString.match(ddmmyyyyRegex);
      if (ddmmyyyyMatch) {
        const [_, day, month, year] = ddmmyyyyMatch.map(Number);
        return new Date(year, month - 1, day);
      }
    
      // Match YYYY-MM-DD
      const yyyymmddRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
      const yyyymmddMatch = dateString.match(yyyymmddRegex);
      if (yyyymmddMatch) {
        const [_, year, month, day] = yyyymmddMatch.map(Number);
        return new Date(year, month - 1, day);
      }
    
      return null; // Return null if no valid date format is detected
    }
    
    // Helper function to get nested values by path
    getValueByPath(obj: any, path: string): any {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    }
     formatDate(isoDate: string): string {
       if(isoDate){
         const date = new Date(isoDate);
         const day = String(date.getDate()).padStart(2, '0');
         const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
         const year = date.getFullYear();
         return `${day}/${month}/${year}`;
       }else{
         return ''
       }
     
     }
   
     listStaffs(){

      this.loginService.listStaffs(this.token)
    .subscribe((response: any) => {
      if (response.status === 1) {
              this.staffDetails =response.data

      }})
   
     


    }

   onInitiatedByChange(event:any){
      let req={
        searchKey:this.searchQuery,
        page:this.pagingConfig.currentPage,
        limit:this.pagingConfig.itemsPerPage
      }


      let body={
        // status:"All",
        // userType:this.storedData.userType[0].userType,
        // products:this.storedData.products
        initiatedBy:event.target.value
      }
    
                  this.loginService.listClientsById(this.token,req,body)
                             .subscribe((response: any) => {
                              this.reminderCases=response.data
                              this.pagingConfig.totalItems = response.totalCount;
                              this.currentCount=response.count
                              this. total=response.totalCount

                             })
    }
    getStatusClass(status: string): string {
      switch (status) {
        case 'New case':
          return 'status-new-case';
        case 'Under Validation':
          return 'status-under-validation';
        case 'Verified':
          return 'status-verified';
        case 'Under Telecall':
          return 'status-under-telecall';
        case 'Interested':
          return 'status-interested';
        case 'Not Interested':
          return 'status-not-interested';
        case 'Client Enrolled':
          return 'status-client-enrolled';
        case 'Field Visit':
          return 'status-field-visit';
        default:
          return 'status-default';
      }
    }

}
