
    <div class="main-wrapper">
    <div class="main-content">
        <header>

            <div class="head-line">

                <p>Staff List</p>
            </div>
            <div class="user-wrapper">
                <p>{{storedData.agencyRefId.agencyName}}</p>

                <div>
                    <img class="log1" [src]="this.storedData.agencyRefId.logo?this.storedData.agencyRefId.logo:'../../../../assets/images/user1.jpeg'">
                </div>
            </div>
        </header>
            <main class="main-2">

               <div class="container">
                <div class="projects">
                    
                    
                    <div class="two-screen">
<!---sec sidebar-->

<div class="second-side">
    <p class="table-heading">Staff list</p>
    <div class="fill-sea">
    <div class="search-m"> 
        <input type="text" id="searchQueryInput1" placeholder="Search here..." value="" [(ngModel)]="searchQuery" >
        <button type="submit" id="searchQuerySubmit">
            <!-- <svg viewBox="0 0 24 24" style="width: 24px; height: 24px;">
        <path fill="#666666" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"></path>
        </svg> -->
        </button>
</div>
<!-- <img src="../../assets/images/filter.svg" class="filter-img"> -->
<!-- <div class="filter-img-container">
    <img src="../../assets/images/filter.svg" class="filter-img">
    <div class="popup">
        <table class="agency-table" id="bg-table">
            <tr>
                <td>Date Wise</td>
            </tr>
            <tr>
                <td>Date Wise</td>
            </tr>
            <tr>
                <td>Date Wise</td>
            </tr>
        </table>
    </div>
</div> -->
</div>


<div class="side-content">
    <div *ngFor="let staff of staffDetails | appFilter:searchQuery">
<div  (click)="viewStaff=true"     [ngClass]="{'main-box': isSelected(staff._id), 'main-box2': !isSelected(staff._id), 'alternate-color': true}"   (click)="selectStaff(staff._id)">
<div    [ngClass]="{'second-list': isSelected(staff._id), 'second-list2': !isSelected(staff._id)}">
<div class="v-img"><img [src]="staff.photo"></div>
<div><h6 class="name-one">{{staff.name}}</h6>
    <span><img src="../../assets/images/call-white.svg" class="call-img">{{staff.phoneNumber}}</span>
    </div>
    </div>
    <img src="../../assets/images/right-arrow.png">
</div>

</div>
    </div>



    <div class="footer-section1">
        <button (click)="addStaff=true;viewStaff=false;editStaff=false;staffForm.reset()"><img src="../../assets/images/plus-blue.png" >Add New Staff</button>
      
     </div>
     <div class="footer-section2">

        <img src="../../assets/images/accruon-footer (2).png">
     </div>
    
    </div>



<!---------------->
                    <div class="card2" *ngIf="viewStaff">
                            
                        <div class="agency-head"  [attr.style]="'background-image: url(' + (selectedStaffDetails.coverImage ? selectedStaffDetails.coverImage : '../../assets/images/pic1.jpg') + '); background-size: cover;'">
                            <img src="{{selectedStaffDetails.photo}}">
                            <div>
                                <p>{{selectedStaffDetails.name}} </p>
                                <span>{{selectedStaffDetails.userType[0].userType}}</span>
                            </div>
                         
                        </div>
                        <p class="table-heading">Staff Details</p> 
                        <table class="agency-table">

       <tr> 
        
    
    </tr>
                            <tr>
                                <td>
                                    <p class="name-two">Address</p>
                                    <p class="name-one">{{selectedStaffDetails.address}}</p>

                                </td>
                                    
                            </tr>
                            <tr>
                                <td>
                                <p class="name-two">Phone number</p>
                                    <p class="name-one">{{selectedStaffDetails.phoneNumber}}</p>
                                 
                            </tr>
                            <tr>
                                <td>
                                <p class="name-two">Whatsapp</p>
                                    <p class="name-one">{{selectedStaffDetails.whatsAppNumber}}</p>
                                  
                            </tr>
                            <tr>
                                <td>
                                <p class="name-two">Email ID</p>
                                    <p class="name-one">{{selectedStaffDetails.email}}</p>
                                   
                            </tr>
                            <tr>
                                <td>
                                <p class="name-two">Password</p>
                                    <p class="name-one">*********</p>
                                 
                            </tr>
                            <tr>
                                <td>
                                    <div class="content">
                                        <button class="add-n" (click)="disableStaff()">Disable Staff</button>
                                        <!---->
                                        <button class="btn-two" (click)="editStaffSetUp()">Edit details</button>
                                    </div>   
                                </td>
                            </tr>
                        </table>
                        <p class="table-heading">Created Clients</p>       
                        <!-- <div class="agency-head">
                            <img src="../../assets/images/user1.jpeg">
                            <p>Allexandera</p>
                        </div> -->
                        
                        <table class="agency-table" id="border-none" *ngFor="let item of selectedStaffDetails.client_details">
                            <tr>
                                <td>
                                    <div>
                                    <p class="name-f">{{item.clientName}}</p>
                                    <p class="place-f">{{item.clientAddress}}</p>
                                </div>
                                <button>Open</button>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td>
                                    <div>
                                    <p class="name-f">Forum Mall</p>
                                    <p class="place-f">Edappalli, Ernakulam</p>
                                </div>
                                <button>Open</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                    <p class="name-f">Forum Mall</p>
                                    <p class="place-f">Edappalli, Ernakulam</p>
                                </div>
                                <button>Open</button>
                                </td>
                            </tr>
                          -->
                        </table>
                    

                        </div>



                        <div class="card2" *ngIf="addStaff">
                            <p class="table-heading">Staff creation</p> 
                            <p class="sub-para">Please fill the details</p>  
                      


<div class="agency-head" id="ext-agency2"   [attr.style]="'background-image: url(' + (staffForm.value.coverImage ? staffForm.value.coverImage : '../../assets/images/Frame 427319013.jpg') + '); background-size: cover;'">
    <div class="image-upload">
        <input type="file" id="fileInput" class="file-input-bg-edit" (change)="upload('staffLogo',$event)">
        <label for="fileInput" class="file-label">
         <img alt="User Image" src="{{staffForm.value.photo ? staffForm.value.photo:'../../../../assets/images/user-no.jpg'}}">
            <img src="../../assets/images/edit.png" class="plus-b"></label>
</div>

                                <div class="main-imglist">
                                    <div class="img-list">
                                     <img src="../../assets/images/1.jpg"  (click)="saveImage('../../assets/images/1.jpg')" class="active-icon small-icon">
                                     <img src="../../assets/images/2.jpg"  (click)="saveImage('../../assets/images/2.jpg')" class="small-icon">
                                     <img src="../../assets/images/3.jpg"  (click)="saveImage('../../assets/images/3.jpg')"class="small-icon">
                                     <img src="../../assets/images/4.jpg"  (click)="saveImage('../../assets/images/4.jpg')" class="small-icon">
                                     <img src="../../assets/images/5.jpg"  (click)="saveImage('../../assets/images/5.jpg')" class="small-icon">
                                     <img src="../../assets/images/6.jpg"  (click)="saveImage('../../assets/images/6.jpg')" class="small-icon">
                                   </div>
                                   <img src="../../assets/images/close-black.svg" class="close-b">
                                   </div>
                             <!-- <div>
                            <div class="edit-icon">
                                <input type="file" id="fileInput" class="file-input2">
                                <label for="fileInput" class="file-label">
                                <img src="images/edit.png"></label>
                            </div>
                         </div> -->
                         </div>
                             <form class="staff-form"  [formGroup]="staffForm">
                                <div class="row">
                                    <div class="col-lg-6 col-xs-12 col-sm-6">
                                <div class="form-group">
                                  <label>Name*</label>
                                  <input type="text" class="form-control" placeholder="Type here" formControlName="name">
                                </div>
                                </div>
                                <div class="col-lg-6 col-xs-12 col-sm-6">
                                    <div class="form-group">
                                      <label>Phone*</label>
                                      <input type="text" class="form-control" placeholder="Type here" formControlName="phoneNumber">
                                    </div>
                                    </div>
                                    <div class="col-lg-12 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                          <label>Roles*</label>
                                          <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="userTypes"  formControlName="userType" >
                                        </ng-multiselect-dropdown>



                                          <!-- <select class="select-al" formControlName="userType">
                                            <option  *ngFor="let each of userTypes" [ngValue]="each._id">{{each.userType}}</option>
                                           </select> -->
                                        </div>
                                        </div>
                                        <div class="col-lg-12 col-xs-12 col-sm-6">
                                            <div class="form-group">
                                              <label>Products*</label>
                                              <ng-multiselect-dropdown [settings]="dropdownproductSettings"  [data]="productDetails"   formControlName="products" >
                                            </ng-multiselect-dropdown>
    
    
    
                                              <!-- <select class="select-al" formControlName="userType">
                                                <option  *ngFor="let each of userTypes" [ngValue]="each._id">{{each.userType}}</option>
                                               </select> -->
                                            </div>
                                            </div>
    
                            <div class="col-lg-6 col-xs-12 col-sm-6 ext-padding">
                            <div class="col-lg-12 col-xs-12 col-sm-6">
                                <div class="form-group">
                                  <label>Whatsapp*</label>
                                  <input type="text" class="form-control" placeholder="Type here" formControlName="whatsAppNumber">
                                </div>
                                </div>
                                <div class="col-lg-12 col-xs-12 col-sm-6">
                                    <div class="form-group">
                                      <label>Email Id*</label>
                                      <input type="text" class="form-control" placeholder="Type here" formControlName="email">
                                    </div>
                                    </div>
                                    </div>
                                    <div class="col-lg-6 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                          <label>Address*</label>
                                          <textarea class="form-control" placeholder="Type here" style="    height: 99px;"  formControlName="address"></textarea>
                                        </div>
                                        </div>
    
    
                                        <div class="col-lg-6 col-xs-12 col-sm-6">
                                            <div class="form-group">
                                              <label>User name*</label>
                                              <input type="text" class="form-control" placeholder="Type here"  formControlName="userName">
                                            </div>
                                            <!-- <div class="form-group">
                                                <label>Gallery</label>&nbsp;&nbsp;
                                                <input type="checkbox"  placeholder="Type here"  formControlName="gallery">
                                              </div> -->
                                            </div>
                                            <div class="col-lg-6 col-xs-12 col-sm-6">
                                                <div class="form-group">
                                                  <label>Password*</label>
                                                  <input type="password" class="form-control" placeholder="Type here" formControlName="password">
                                                </div>
                                                </div>
                                    </div>
                              </form>
    
    
    
                            <div class="content">
                                <button class="add-n" (click)="addStaff=false">Back</button>
                                
                                <button class="btn-two" (click)="staffCrud('Add')">Create Staff</button>
                            </div>
                    
                        
    
                            </div>



                            <div class="card2" *ngIf="editStaff">
                                <p class="table-heading">Staff creation</p> 
                                <p class="sub-para">Please fill the details</p>  
                          
    
    
    <div class="agency-head" id="ext-agency2"   [attr.style]="'background-image: url(' + (staffForm.value.coverImage ? staffForm.value.coverImage : '../../assets/images/Frame 427319013.jpg') + '); background-size: cover;'">
        <div class="image-upload">
            <input type="file" id="fileInput" class="file-input-bg-edit" (change)="upload('staffLogo',$event)">
            <label for="fileInput" class="file-label">
             <img alt="User Image" src="{{staffForm.value.photo ? staffForm.value.photo:'../../../../assets/images/user-no.jpg'}}">
                <img src="../../assets/images/edit.png" class="plus-b"></label>
    </div>
    
                                    <div class="main-imglist">
                                        <div class="img-list">
                                         <img src="../../assets/images/1.jpg"  (click)="saveImage('../../assets/images/1.jpg')" class="small-icon"  [ngClass]="{ 'active-icon': staffForm.value.coverImage=='../../assets/images/1.jpg'}">
                                         <img src="../../assets/images/2.jpg"  (click)="saveImage('../../assets/images/2.jpg')" class="small-icon"  [ngClass]="{ 'active-icon': staffForm.value.coverImage=='../../assets/images/2.jpg'}">
                                         <img src="../../assets/images/3.jpg"  (click)="saveImage('../../assets/images/3.jpg')"class="small-icon"  [ngClass]="{ 'active-icon': staffForm.value.coverImage=='../../assets/images/3.jpg'}">
                                         <img src="../../assets/images/4.jpg"  (click)="saveImage('../../assets/images/4.jpg')" class="small-icon"  [ngClass]="{ 'active-icon': staffForm.value.coverImage=='../../assets/images/4.jpg'}">
                                         <img src="../../assets/images/5.jpg"  (click)="saveImage('../../assets/images/5.jpg')" class="small-icon"  [ngClass]="{ 'active-icon': staffForm.value.coverImage=='../../assets/images/5.jpg'}">
                                         <img src="../../assets/images/6.jpg"  (click)="saveImage('../../assets/images/6.jpg')" class="small-icon"  [ngClass]="{ 'active-icon': staffForm.value.coverImage=='../../assets/images/6.jpg'}">
                                       </div>
                                       <img src="../../assets/images/close-black.svg" class="close-b">
                                       </div>
                             
                             </div>






















                             
                                 <form class="staff-form"  [formGroup]="staffForm">
                                    <div class="row">
                                        <div class="col-lg-6 col-xs-12 col-sm-6">
                                    <div class="form-group">
                                      <label>Name*</label>
                                      <input type="text" class="form-control" placeholder="Type here" [(ngModel)]="selectedStaffDetails.name" formControlName="name">
                                    </div>
                                    </div>
                                    <div class="col-lg-6 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                          <label>Phone*</label>
                                          <input type="text" class="form-control" placeholder="Type here"  [(ngModel)]="selectedStaffDetails.phoneNumber" formControlName="phoneNumber">
                                        </div>
                                        </div>
                                        <div class="col-lg-12 col-xs-12 col-sm-6">
                                            <div class="form-group">
                                              <label>Roles*</label>
                                             
                                              <!-- <select class="select-al" formControlName="userType" [(ngModel)]="selectedStaffDetails.userType[0]._id">
                                                <option  *ngFor="let each of userTypes" [ngValue]="each._id">{{each.userType}}</option>
                                               </select> -->
                                               <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="userTypes"  formControlName="userType"  [(ngModel)]="selectedStaffDetails.userType">
                                            </ng-multiselect-dropdown>
    
    
                                            </div>
                                            </div>
                                            <div class="col-lg-12 col-xs-12 col-sm-6">
                                                <div class="form-group">
                                                  <label>Products*</label>
                                                  <ng-multiselect-dropdown [settings]="dropdownproductSettings"  [data]="productDetails"   formControlName="products" [(ngModel)]="selectedStaffDetails.products" >
                                                </ng-multiselect-dropdown>
        
        
        
                                                  <!-- <select class="select-al" formControlName="userType">
                                                    <option  *ngFor="let each of userTypes" [ngValue]="each._id">{{each.userType}}</option>
                                                   </select> -->
                                                </div>
                                                </div>
        
                                <div class="col-lg-6 col-xs-12 col-sm-6 ext-padding">
                                <div class="col-lg-12 col-xs-12 col-sm-6">
                                    <div class="form-group">
                                      <label>Whatsapp*</label>
                                      <input type="text" class="form-control" placeholder="Type here" [(ngModel)]="selectedStaffDetails.whatsAppNumber"  formControlName="whatsAppNumber">
                                    </div>
                                    </div>
                                    <div class="col-lg-12 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                          <label>Email Id*</label>
                                          <input type="text" class="form-control" placeholder="Type here" formControlName="email"   [(ngModel)]="selectedStaffDetails.email" >
                                        </div>
                                        </div>
                                        </div>
                                        <div class="col-lg-6 col-xs-12 col-sm-6">
                                            <div class="form-group">
                                              <label>Address*</label>
                                              <textarea class="form-control" placeholder="Type here" style="    height: 99px;"  [(ngModel)]="selectedStaffDetails.address"  formControlName="address"></textarea>
                                            </div>
                                            </div>
        
        
                                            <div class="col-lg-6 col-xs-12 col-sm-6">
                                                <div class="form-group">
                                                  <label>User name*</label>
                                                  <input type="text" class="form-control" readonly placeholder="Type here"  formControlName="userName"   [(ngModel)]="selectedStaffDetails.userName">
                                                </div>
                                                <!-- <div class="form-group">
                                                    <label>Gallery</label>
                                                    <input type="checkbox"  readonly placeholder="Type here"  formControlName="gallery"   [(ngModel)]="selectedStaffDetails.gallery">
                                                  </div>
                                                   -->
                                                
                                                </div>
                                                <!-- <div class="col-lg-6 col-xs-12 col-sm-6">
                                                    <div class="form-group">
                                                      <label>Password*</label>
                                                      <input type="password" class="form-control" placeholder="Type here" formControlName="password">
                                                    </div>
                                                    </div> -->
                                        </div>
                                  </form>
        
        
        
                                <div class="content">
                                    <button class="add-n" (click)="addStaff=false;editStaff=false;viewStaff=true">Back</button>
                                    
                                    <button class="btn-two" (click)="staffCrud('Edit')">Update Staff</button>
                                </div>
                        
                            
        
                                </div>
    










       </div>                 
                 
                    </div>
                   
            </div>

    
            </main>
   
    </div>
    </div>


<!-- staff add  -->


<!--  -->
<!-- 2 -->
<!-- <body>
    
   
    <div class="sidebar">
            <div class="sidebar-brand">
                <h2>
                <img src="../../assets/images/marketingApp-logo.png">
     
            </div>
            <div class="sidebar-menu">
                <ul>
                    <li>
                        <a>
                         <div class="inactive-img">
                            <div>
                                <img src="../../assets/images/dashboard.svg">
                                 
                            </div>
                            <span>Dashboard</span>
                        </div>
                        </a>
                    </li>
                    <li>
                        <a>
                         <div class="active">
                            <div><img src="../../assets/images/staff-list.svg" height="34px"></div>
                            <span>Staff List</span>
                        </div>
                        </a>
                    </li>
                    <li>
                        <a>
                          <div class="inactive-img">
                            <div><img src="../../assets/images/products.svg"></div>
                            <span>Products</span>
                           </div>
                        </a>
                    </li>
                    <li>
                        <a>
                          <div class="inactive-img">
                            <div><img src="../../assets/images/clients.svg"></div>
                            <span>Clients</span>
                           </div>
                        </a>
                    </li>
                    <li>
                        <a>
                          <div class="inactive-img">
                            <div><img src="../../assets/images/reports.svg"></div>
                            <span>Reports</span>
                           </div>
                        </a>
                    </li>
                </ul>

   <div class="last-icon">
    <div class="sec-one">
     
        <div class="user-sec">
        <p>Jessica lofez</p>
        <span>Super Admin</span>
       </div>
    </div>
    <div class="sec-two">
        
        <p>Logout</p>
    </div>
</div>




            </div>
    </div>
    <div class="main-wrapper">
    <div class="main-content">
            <header>
           
                    <div class="head-line">
                 
                        <p>Staff creation</p>
                    </div>
                <div class="user-wrapper">
                   <p>Skillplex Technologies</p>
                  
                    <div>
                        <img class="log1" src="../../assets/images/Ellipse 2.png">
                    </div>
                </div>
            </header>
            
            <main class="main-2">

               <div class="container">
                <div class="projects">
                    
                    
                    <div class="two-screen">


<div class="second-side">
    <p class="table-heading">Staff list</p>
    <div class="fill-sea">
    <div class="search-m"> 
        <input type="text" id="searchQueryInput1" placeholder="Search here..." value="">
        <button type="submit" id="searchQuerySubmit"><svg viewBox="0 0 24 24" style="width: 24px; height: 24px;">
        <path fill="#666666" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"></path>
        </svg>
        </button>
</div>

<div class="filter-img-container">
    <img src="../../assets/images/filter.svg" class="filter-img">
    <div class="popup">
        <table class="agency-table" id="bg-table">
            <tr>
                <td>Date Wise</td>
            </tr>
            <tr>
                <td>Date Wise</td>
            </tr>
            <tr>
                <td>Date Wise</td>
            </tr>
        </table>
    </div>
</div>
</div>


<div class="side-content">
<div class="main-box2 alternate-color">
<div class="second-list2">
<div class="v-img"><img src="../../assets/images/user1.jpeg"></div>
<div><h6 class="name-one">Chandra mohan</h6>
    <span><img src="../../assets/images/call.svg" class="call-img">98765432343</span>
    </div>
    </div>
    <img src="../../assets/images/right-arrow.png">
</div>
<div class="main-box2 alternate-color">
    <div class="second-list2">
    <div class="v-img"><img src="../../assets/images/user1.jpeg"></div>
    <div><h6 class="name-one">CHANDRA mohan</h6>
        <span><img src="../../assets/images/call.svg" class="call-img">98765432343</span>
        </div>
        </div>
     
    </div>
    <div class="main-box2 alternate-color">
        <div class="second-list2">
        <div class="v-img"><img src="../../assets/images/user1.jpeg"></div>
        <div><h6 class="name-one">CHANDRA mohan</h6>
            <span><img src="../../assets/images/call.svg" class="call-img">98765432343</span> 
            </div>
            </div>
           
        </div>
    <div class="main-box2 alternate-color">
        <div class="second-list2">
        <div class="v-img"><img src="../../assets/images/user1.jpeg"></div>
        <div><h6 class="name-one">CHANDRA mohan</h6>
            <span><img src="../../assets/images/call.svg" class="call-img">98765432343</span> 
            </div>
            </div>
            
        </div>

    </div>



    <div class="footer-section1">
        <button><img src="../../assets/images/plus-blue.svg">Add New Staff</button>
      
     </div>
     <div class="footer-section2">

        <img src="../../assets/images/accruon-footer.png">
     </div>
    
    </div>




                    <div class="card2">
                        <p class="table-heading">Staff creation</p> 
                        <p class="sub-para">Please fill the details</p>  
                        <div class="agency-head" id="agency-h1">

                            <input type="file" id="fileInput" class="file-input-bg-edit">
                            <label for="fileInput" class="file-label">
                             <img alt="User Image" src="../../assets/images/user-no.jpg">
                                <img src="../../assets/images/plus-black.png" class="plus-b"></label>
 
 
                             <div>
                        
                            <div class="edit-icon">
                                <input type="file" id="fileInput" class="file-input2">
                                <label for="fileInput" class="file-label">
                                <img src="../../assets/images/edit.png"></label>
                            </div>
                         </div>
                         </div>
                         <form class="staff-form">
                            <div class="row">
                                <div class="col-lg-6 col-xs-12 col-sm-6">
                            <div class="form-group">
                              <label>Name*</label>
                              <input type="text" class="form-control" placeholder="Type here">
                            </div>
                            </div>
                            <div class="col-lg-6 col-xs-12 col-sm-6">
                                <div class="form-group">
                                  <label>Phone*</label>
                                  <input type="text" class="form-control" placeholder="Type here">
                                </div>
                                </div>
                                <div class="col-lg-12 col-xs-12 col-sm-6">
                                    <div class="form-group">
                                      <label>Roles*</label>
                                      <select class="select-al">
                                        <option value="" selected>Select your team</option>
                                        <option value="">Coverage</option>
                                        <option value="">Coverage</option>
                                        <option value="">Coverage</option>
                                       </select>
                                    </div>
                                    </div>

                        <div class="col-lg-6 col-xs-12 col-sm-6 ext-padding">
                        <div class="col-lg-12 col-xs-12 col-sm-6">
                            <div class="form-group">
                              <label>Whatsapp*</label>
                              <input type="text" class="form-control" placeholder="Type here">
                            </div>
                            </div>
                            <div class="col-lg-12 col-xs-12 col-sm-6">
                                <div class="form-group">
                                  <label>Email Id*</label>
                                  <input type="text" class="form-control" placeholder="Type here">
                                </div>
                                </div>
                                </div>
                                <div class="col-lg-6 col-xs-12 col-sm-6">
                                    <div class="form-group">
                                      <label>Address*</label>
                                      <textarea class="form-control" placeholder="Type here" style="    height: 99px;"></textarea>
                                    </div>
                                    </div>


                                    <div class="col-lg-6 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                          <label>User name*</label>
                                          <input type="text" class="form-control" placeholder="Type here">
                                        </div>
                                        </div>
                                        <div class="col-lg-6 col-xs-12 col-sm-6">
                                            <div class="form-group">
                                              <label>Password*</label>
                                              <input type="password" class="form-control" placeholder="Type here">
                                            </div>
                                            </div>
                                </div>
                          </form>



                        <div class="content">
                            <button class="add-n">Back</button>
                         
                            <button class="btn-two">Create Staff</button>
                        </div>
                
                    

                        </div>
       </div>                 
                 
                    </div>
                   
            </div>

    
            </main>
   
    </div>
    </div>



</body> -->