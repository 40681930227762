import { environment } from "src/environments/environment";

export const AppConfig = {

  // GOOGLE_API_KEY: '',
   // /********local Server ********/
    // API_BASE_URL: 'http://192.168.1.75:3002/api',
    // API_BASE_URL: 'https://apikerala.rijasprime.com/api',
    // API_BASE_URL: 'https://apika.rijasprime.com/api',
      //  API_BASE_URL: 'http://192.168.11.74:3000/api',
  //API_BASE_URL: 'http://localhost:3000/api'
  HEADER_VALUE:"",
  API_BASE_URL:  environment.API_BASE_URL,
  GOOGLE_API_KEY : environment.GOOGLE_API_KEY,

}