// / filter.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appFilter' })
export class FilterPipe implements PipeTransform {
  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param searchText search string
   * @returns list of elements filtered by search text or []
   */
  transform(items: any[], searchText: string): any[] {
 console.log(searchText);
 
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter((it:any) => {
      console.log(it)
      if(it.clientName){
        return it.clientName.toLocaleLowerCase().includes(searchText);
      }else if(it.name)
      return it.name.toLocaleLowerCase().includes(searchText);
    });
  }
}
