import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import moment from 'moment';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-works-page',
  templateUrl: './works-page.component.html',
  styleUrls: ['./works-page.component.css']
})
export class WorksPageComponent implements OnInit {
  private intervalSubscription!: Subscription;
  PuncedStaffs:any=[]
  title = 'ngx-paging-sample';
  currentPage: number = 1;
  AllocationFlag:any=false
  PuncedTeleCallerStaffs:any=[]
  userTypes:any=[]
  sortField:any
  sortOrder:any
  dropdownSettings:any
  itemsPerPage: number = 25;
  totalItems: number =0
  tableSize: number[] = [5, 10, 20, 50, 100];
  pagingConfig: any = {}
  pageNo: any;
  size: any;
  sortDirection = 'asc'; // 'asc' for ascending, 'desc' for descending
  currentSortColumn = '';
  storedData:any={}
  searchQuery:any=''
  currentCount:any=0
  total:any=0
  token:any
  selectedCase:any
  showingCases:any=[]
  selectedCases:any=[]
  ShowFilter:any=true
  selectedItems = [];
  tab:any
  staffDetails:any=[]
  productSettings:any={}
  selectedStatuses:any=[]
  selectedProducts:any=[]
  userTypesTelleCaller:any=[]
  autoType:any='backend'
  dropdownSettingsForSelect:any={}
  // currentSortColumn: string = '';

  // sortOrder: 'asc' | 'desc' = 'asc';

  cases = [
    { name: 'All Cases', count: 0 ,item_id:'All',item_text:'All'},
    { name: 'Fresh Cases', count: 0 ,item_id:'New case',item_text:'New case'},
    { name: 'Under Validation', count:0 ,item_id:'Under Validation',item_text:'Under Validation'},
    { name: 'Verified Cases', count: 0 ,item_id:'Verified',item_text:'Verified'},
    { name: 'Under TeleCall', count: 0 ,item_id:'Under TeleCall',item_text:'Under TeleCall'},
    { name: 'Interested', count: 0,item_id:'Interested',item_text:'Interested'},
    { name: 'Meeting Case', count: 0,item_id:'Field Visit',item_text:'Field Visit' }
  ];
  constructor(public loginService:LoginService,public router:Router,private cd: ChangeDetectorRef) {
    this.pagingConfig = {
      itemsPerPage: this.itemsPerPage,
      currentPage: this.currentPage,
      totalItems: this.totalItems
    }
   }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: this.ShowFilter,
      appendToBody: true, // Ensures dropdown appears above everything

    };
    this.productSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: this.ShowFilter,
      appendToBody: true, // Ensures dropdown appears above everything

    };
   
    this.pageNo = 1;
    this.size = 20;
    this.token = localStorage.getItem('token');
    this.storedData= JSON.parse(localStorage.getItem("user") || '{}');
    this.tab = localStorage.getItem('tab');

console.log(this.storedData.userType);
this.selectedProducts=this.storedData.products
this.intervalSubscription = interval(15000
).subscribe(() => {
  this.listStatusCount();
  this.tab = localStorage.getItem('tab');

  this.selectCase(this.tab)

});
this.listStatusCount()
this.listUserType()
this.listStaffs()
// this.listPuncedBackEndStaffs()

     if(this.storedData.userType[0].userType=='Tele Caller'){

      this.selectedCases=this.cases.filter((caseType:any) => caseType.name == 'All Cases' || caseType.name == 'Under TeleCall' );

    }else if(this.storedData.userType[0].userType=='Backend Staff'){

      this.selectedCases=  this.cases.filter((caseType:any) => caseType.name == 'All Cases'||caseType.name == 'Fresh Cases' || caseType.name == 'Under Validation' || caseType.name == 'Verified Cases');

    }else if(this.storedData.userType[0].userType=='Supervisor'){

      this.selectedCases= this.cases.filter((caseType:any) => caseType.name == 'All Cases' || caseType.name == 'Interested' );

    }else if(this.storedData.userType[0].userType=='Manager'){

      this.selectedCases= this.cases.filter((caseType:any) => caseType.name == 'All Cases' || caseType.name == 'Meeting Case' || caseType.name == 'Interested');

    }else if(this.storedData.userType[0].userType=='Client Admin'){

      this.selectedCases=this.cases

    }
this.selectedStatuses=this.selectedCases
    this.selectCase(this.tab)


  }
  selectCase(name: any): void {
    console.log("ggjh");
    localStorage.setItem('tab',name);
    this.tab=name
    this.selectedCase = name;
    // this.cd.detectChanges()
    this.ListCases()
  }
  onTableDataChange(event: any) {
    this.pagingConfig.currentPage = event;
    this.ListCases()
  }
  onTableSizeChange(event: any): void {
    this.pagingConfig.itemsPerPage = event.target.value;
    this.pagingConfig.currentPage = 1;
    this.size = event.target.value
   this.ListCases()

  }

  
  Search(event: any) {


  }


  ExcelUpload(event:any){
    console.log("inside");
    

    this.loginService.ExcelUpload(this.token,event.target.files[0])
    .subscribe((response: any) => {
    if (response.status === 100) {
      Swal.fire("Success")
      this.selectCase(this.selectedCase)
    // this.showingCases=response.data
    // this.pagingConfig.totalItems = response.data.length;

     }
   })


  }

  ListCases(){
    console.log(this.searchQuery);
    
      switch (this.selectedCase) {
        case 'All Cases':
          let req={
            searchKey:this.searchQuery,
            page:this.pagingConfig.currentPage,
            limit:this.pagingConfig.itemsPerPage
          }


          let body={
            status:"All",
            userType:this.storedData.userType[0].userType,
            products:this.selectedProducts,
            sortField:this.sortField,
            sortOrder:this.sortOrder
          }
        
                      this.loginService.listClients(this.token,req,body)
                                 .subscribe((response: any) => {
                                 if (response.status === 1) {
                                 this.showingCases=response.data
                                 this.pagingConfig.totalItems = response.totalCount;
                                 this.currentCount=response.count
                                 this. total=response.totalCount
  
                                  }
                                })


        return
        
        case 'Fresh Cases':
          let req2={
            searchKey:this.searchQuery,
            page:this.pagingConfig.currentPage,
            limit:this.pagingConfig.itemsPerPage
          }


          let body2={
            status:"New case",
            userType:this.storedData.userType[0].userType,
            products:this.selectedProducts,
            sortField:this.sortField,
            sortOrder:this.sortOrder

          }
        
                      this.loginService.listClients(this.token,req2,body2)
                                 .subscribe((response: any) => {
                                 if (response.status === 1) {
                                 this.showingCases=response.data
                                 this.pagingConfig.totalItems = response.totalCount;
                                 this.currentCount=response.count
                                this. total=response.totalCount

  
                                  }
                                })
                                
                              

        return
        case 'Under Validation':

        let req3={
          searchKey:this.searchQuery,
          page:this.pagingConfig.currentPage,
          limit:this.pagingConfig.itemsPerPage,
         
        }


        let body4={
          status:"Under Validation",
          userType:this.storedData.userType[0].userType,
          products:this.selectedProducts,
          sortField:this.sortField,
            sortOrder:this.sortOrder

        }
      
                    this.loginService.listClients(this.token,req3,body4)
                               .subscribe((response: any) => {
                               if (response.status === 1) {
                               this.showingCases=response.data
                               this.pagingConfig.totalItems = response.totalCount;
                               this.currentCount=response.count
                              this. total=response.totalCount


                                }
                              })
                  

        return

        case 'Verified Cases':
          let req4={
            searchKey:this.searchQuery,
            page:this.pagingConfig.currentPage,
            limit:this.pagingConfig.itemsPerPage
          }
  
  
          let body5={
            status:"Verified",
            userType:this.storedData.userType[0].userType,
            products:this.selectedProducts,
            sortField:this.sortField,
            sortOrder:this.sortOrder

          }
        
                      this.loginService.listClients(this.token,req4,body5)
                                 .subscribe((response: any) => {
                                 if (response.status === 1) {
                                 this.showingCases=response.data
                                 this.pagingConfig.totalItems = response.totalCount;
                                 this.currentCount=response.count
                                this. total=response.totalCount
  
  
                                  }
                                })
                    
  
  

        return


        case 'Under TeleCall':
          let req5={
            searchKey:this.searchQuery,
            page:this.pagingConfig.currentPage,
            limit:this.pagingConfig.itemsPerPage
          }
  
  
          let body6={
            status:"Under TeleCall",
            userType:this.storedData.userType[0].userType,
            products:this.selectedProducts,
            sortField:this.sortField,
            sortOrder:this.sortOrder

          }
        
                      this.loginService.listClients(this.token,req5,body6)
                                 .subscribe((response: any) => {
                                 if (response.status === 1) {
                                 this.showingCases=response.data
                                 this.pagingConfig.totalItems = response.totalCount;
                                 this.currentCount=response.count
                                this. total=response.totalCount
  
  
                                  }
                                })
                    
  
  

        return

        case 'Interested':
          let req7={
            searchKey:this.searchQuery,
            page:this.pagingConfig.currentPage,
            limit:this.pagingConfig.itemsPerPage
          }
  
  
          let body8={
            status:["Interested"],
            userType:this.storedData.userType[0].userType,
            products:this.selectedProducts,
            sortField:this.sortField,
            sortOrder:this.sortOrder

          }
        
                      this.loginService.listClientsForSupervisor(this.token,req7,body8)
                                 .subscribe((response: any) => {
                                 if (response.status === 1) {
                                 this.showingCases=response.data
                                 this.pagingConfig.totalItems = response.totalCount;
                                 this.currentCount=response.data.length
                                this. total=response.totalCount
  
  
                                  }
                                })
                    
  
  

        return


        case 'Meeting Case':
          let req9={
            searchKey:this.searchQuery,
            page:this.pagingConfig.currentPage,
            limit:this.pagingConfig.itemsPerPage
          }
  
  
          let body10={
            status:["Field Visit"],
            userType:this.storedData.userType[0].userType,
            products:this.selectedProducts,
            sortField:this.sortField,
            sortOrder:this.sortOrder
          }
        
                      this.loginService.listClientsForSupervisor(this.token,req9,body10)
                                 .subscribe((response: any) => {
                                 if (response.status === 1) {
                                 this.showingCases=response.data
                                 this.pagingConfig.totalItems = response.totalCount;
                                 this.currentCount=response.data.length
                                this. total=response.totalCount
  
  
                                  }
                                })
                    
  
  

        return


      

      }

  


  }
  ClientDetail(flag:any,obj:any){
    obj.flag=flag
    localStorage.setItem('Work',JSON.stringify(obj))
    this.router.navigate(["/add-work-page"])

  }
  DownloadTemplate(){
    this.loginService.DownloadTemplate(this.token)
    .subscribe((response: any) => {
    // if (response.status === 1) {
      window.open(response.data,"_blank"); 

    //  }
   })
  }
   formatDate(isoDate: string): string {
    if(isoDate){
      const date = new Date(isoDate);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }else{
      return ''
    }
  
  }


  
    format(date:any){
          //  return moment(date).format('lll');
           return moment(date).format('DD-MM-YYYY \nHH:mma');
         }

  sortTable(column: string): void {
    // console.log("nvnvss");
    
   this.sortField=column
    if (this.currentSortColumn === column) {
      // Toggle the sort order if the column is already sorted
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      // Set the column to the current sort column and default to ascending
      this.currentSortColumn = column;
      this.sortOrder = 'asc';
    }
    // console.log(this.sortField,this.sortOrder);

  this.ListCases()
  
    // this.showingCases.sort((a: any, b: any) => {
    //   let valueA = this.getValueByPath(a, column);
    //   let valueB = this.getValueByPath(b, column);
  
    //   // Handle null or undefined values
    //   if (valueA == null) return this.sortOrder === 'asc' ? 1 : -1;
    //   if (valueB == null) return this.sortOrder === 'asc' ? -1 : 1;
  
    //   // Attempt to parse dates
    //   const dateA = this.parseCustomDate(valueA);
    //   const dateB = this.parseCustomDate(valueB);
  
    //   if (dateA && dateB) {
    //     // Compare as dates
    //     if (dateA < dateB) return this.sortOrder === 'asc' ? -1 : 1;
    //     if (dateA > dateB) return this.sortOrder === 'asc' ? 1 : -1;
    //     return 0;
    //   }
  
    //   // Compare as numbers if both are numeric
    //   if (!isNaN(valueA) && !isNaN(valueB)) {
    //     valueA = Number(valueA);
    //     valueB = Number(valueB);
    //     return this.sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
    //   }
  
    //   // Compare as strings (case insensitive)
    //   valueA = String(valueA).toLowerCase();
    //   valueB = String(valueB).toLowerCase();
    //   if (valueA < valueB) return this.sortOrder === 'asc' ? -1 : 1;
    //   if (valueA > valueB) return this.sortOrder === 'asc' ? 1 : -1;
    //   return 0;
    // });
  }
  
  // Helper function to parse custom date formats
  parseCustomDate(dateString: string): Date | null {
    // Match DD/MM/YYYY
    const ddmmyyyyRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    const ddmmyyyyMatch = dateString.match(ddmmyyyyRegex);
    if (ddmmyyyyMatch) {
      const [_, day, month, year] = ddmmyyyyMatch.map(Number);
      return new Date(year, month - 1, day);
    }
  
    // Match YYYY-MM-DD
    const yyyymmddRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
    const yyyymmddMatch = dateString.match(yyyymmddRegex);
    if (yyyymmddMatch) {
      const [_, year, month, day] = yyyymmddMatch.map(Number);
      return new Date(year, month - 1, day);
    }
  
    return null; // Return null if no valid date format is detected
  }
  
  // Helper function to get nested values by path
  getValueByPath(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }
  


    listStatusCount() {
      let data={
        userType:this.storedData.userType[0].userType,
        products:this.storedData.products

      }
      this.loginService.listStatusCount(this.token,data).subscribe((response: any) => {
        if (response.status === 1) {
          const responseData = response.data;
    

        
          // Map the response data to update counts in the cases array
         this.cases.map((caseItem:any) => {
console.log(caseItem);

          if(caseItem.name=='Fresh Cases'){
            const matchedResponse = response.data.find(
              (data: any) =>
                
                data._id == 'New case'
            );
            console.log(caseItem.name,matchedResponse,responseData);
            
            caseItem.count=matchedResponse?matchedResponse.count:0
          }else if(caseItem.name=='Under Validation'){
// console.log("erewr");

            const matchedResponse = response.data.find(
              (data: any) => data._id === 'Under Validation'
            );
            console.log(matchedResponse);
            
            caseItem.count=matchedResponse?matchedResponse.count:0
          }else if(caseItem.name=='Verified Cases'){

            const matchedResponse = response.data.find(
              (data: any) => data._id === 'Verified'
            );
            caseItem.count=matchedResponse?matchedResponse.count:0
          }else if(caseItem.name=='Under TeleCall'){

            const matchedResponse = response.data.find(
              (data: any) => data._id === 'Under TeleCall'
            );
            caseItem.count=matchedResponse?matchedResponse.count:0
          }
          else if(caseItem.name=='Interested'){

            const matchedResponse = response.data.find(
              (data: any) => data._id === 'Interested'
            );
            caseItem.count=matchedResponse?matchedResponse.count:0
          }
          else if(caseItem.name=='Meeting Case'){

            const matchedResponse = response.data.find(
              (data: any) => data._id === 'Field Visit'
            );
            caseItem.count=matchedResponse?matchedResponse.count:0
          }
          
          else if(caseItem.name=='All Cases'){

            const matchedResponse = response.data.find(
              (data: any) => data._id === 'All'
            );
            caseItem.count=response.totalCount?response.totalCount:0
           
          }
           
          });
        }
      });
    }

    disable(eachCase:any){


      Swal.fire({
        title: 'Disable File?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        html: `
          <textarea id="commentTextarea" class="swal2-textarea" 
            maxlength="58" 

          placeholder="Enter your comments here..." style="width: 100%; height: 100px;
         margin:0px">
        `,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        preConfirm: () => {
          // Fetch the comment entered in the textarea
          var comment = (document.getElementById('commentTextarea') as HTMLTextAreaElement)?.value;
          console.log(comment.length);
          
        comment=comment.trimStart()

          if (!comment || comment.length > 58) {
            Swal.showValidationMessage('Please enter a comment max 15 length');
            return false;
          }
          
          return { comment }; // Pass the comment for further use
        }
      }).then((result) => {
        if (result.isConfirmed) {
      result.value.comment=  result.value.comment.trimStart()
          let request={
            id:eachCase._id,
            disableReason:  result.value.comment?.toUpperCase()
          }
    
          this.loginService.updateActiveStatus(this.token,request)
          .subscribe((response: any) => {
          if (response&&response.status === 1) {
            Swal.fire("Success")
            this.listStatusCount()

        this.ListCases()
    
    
           }else{
            Swal.fire(response.errorDetails)
           }
    
        })
     
     } })
    
      







































    }

    listStaffs(){

      this.loginService.listStaffs(this.token)
    .subscribe((response: any) => {
      if (response.status === 1) {
              this.staffDetails =response.data
              // this.staffDetails.filter((userType:any) => userType.userType == 'Backend Staff');

              this.staffDetails.map((detail: any) => {

                detail.item_id= detail._id,
                detail.item_text= detail.name
              
              })
      }})
   
     


    }
    listPuncedBackEndStaffs(){

      let req={
        "userType":this.userTypes[0]._id,
        "key":"auto"
        
        
        }
        

      this.loginService.listPuncedBackEndStaffs(this.token,req)
      .subscribe((response: any) => {
      if (response.status === 1) {
        // Swal.fire("Success")
           this.PuncedStaffs=response.result
           console.log("hfhfgkjh",this.PuncedStaffs);
           
            this.PuncedStaffs.map((detail: any) => {

              detail.item_id= detail._id,
              detail.item_text= detail.name
            
            })
          
         }})
        

       
   
     


    }
    allocate(){
      let telecaller
      if(this.autoType=='backend'){
        telecaller=false
      }
      else if(this.autoType=='telle'){
        telecaller=true

      }
      const arr: any[] = this.selectedItems.map((detail: any) => detail.item_id);


      let  req={

        userIds:arr,
        telecaller:telecaller

      }
      this.loginService.autoAllocation(this.token,req)
      .subscribe((response: any) => {
      if (response.status === 1) {
        this.AllocationFlag=false
        this.selectedItems=[]
        Swal.fire("Success")
this.listStatusCount()

        this.ListCases()


       }else{console.log(response.error);
     
       
        Swal.fire(response.error.message)
       }
     })
     
    }

    listUserType(){
      this.loginService.listUserType(this.token)
      .subscribe((response: any) => {
        if (response.status === 1) {
                this.userTypes =response.data
    
                this.userTypes =   this.userTypes.filter((userType:any) => userType.userType == 'Backend Staff');
                this.userTypesTelleCaller =  response.data.filter((userType:any) => userType.userType == 'Tele Caller');

               this.listPuncedBackEndStaffs() 
               this.listTelleCaller()
                  
  
        }
      })
     
    }

    listTelleCaller(){
      let req={
        "userType":this.userTypesTelleCaller[0]._id,
        "key":"auto"
        
        
        }
        

      this.loginService.listPuncedBackEndStaffs(this.token,req)
      .subscribe((response: any) => {
      if (response.status === 1) {
        // Swal.fire("Success")
           this.PuncedTeleCallerStaffs=response.result
          //  console.log("hfhfgkjh",this.PuncedStaffs);
           
            this.PuncedTeleCallerStaffs.map((detail: any) => {

              detail.item_id= detail._id,
              detail.item_text= detail.name
            
            })
          
          }
}
      )
    }

    // getStatusClass(status: string): string {
    //   switch (status) {
    //     case 'New Case':
    //       return 'new-case';
    //     case 'Under Validation':
    //       return 'under-validation';
    //     case 'Verified':
    //       return 'verified';
    //     case 'Under TeleCall':
    //       return 'under-telecall';
    //     default:
    //       return ''; // Default class or empty if no match
    //   }
    // }
    
    ngOnDestroy(): void {
      if (this.intervalSubscription) {
        this.intervalSubscription.unsubscribe();
      }
    }


    onInitiatedByChange(event:any){
      let req={
        searchKey:this.searchQuery,
        page:this.pagingConfig.currentPage,
        limit:this.pagingConfig.itemsPerPage
      }


      let body={
        // status:"All",
        // userType:this.storedData.userType[0].userType,
        // products:this.storedData.products
        initiatedBy:event.target.value
      }
    
                  this.loginService.listClientsById(this.token,req,body)
                             .subscribe((response: any) => {
                              this.showingCases=response.data
                              this.pagingConfig.totalItems = response.totalCount;
                              this.currentCount=response.count
                              this. total=response.totalCount

                             })
    }


    getStatusClass(status: string): string {
      switch (status) {
        case 'New case':
          return 'status-new-case';
        case 'Under Validation':
          return 'status-under-validation';
        case 'Verified':
          return 'status-verified';
        case 'Under TeleCall':
          return 'status-under-telecall';
        case 'Interested':
          return 'status-interested';
        case 'Not Interested':
          return 'status-not-interested';
        case 'Client Enrolled':
          return 'status-client-enrolled';
        case 'Field Visit':
          return 'status-field-visit';
        default:
          return 'status-default';
      }
    }

    PushStatus(){

    }
    
  }