
<body>
<div class="sidebar">
    <div class="sidebar-brand">
        <h2>
        <img src="../../assets/images/marketingApp-logo (1).png">
</h2>
    </div>
    <div class="sidebar-menu">
        <ul>
            <li>
                <a>
                 <div class="inactive-img" (click)="click='dashboard';route()"  [ngClass]="{ 'active' : click=='dashboard' }">
                    <div>
                        <img src="../../assets/images/dashboard.svg">
                         
                    </div>
                    <span>Dashboard</span>
                </div>
                </a>
            </li>
            <li *ngIf=" this.storedData.userType[0].userType=='Client Admin'">
                <a>
                 <div class="inactive-img"   (click)="click='staff';route()"  [ngClass]="{ 'active' : click=='staff' }">
                    <div><img src="../../assets/images/staff-list.svg" height="34px"></div>
                    <span>Staff List</span>
                </div>
                </a>
            </li>
            <li *ngIf=" this.storedData.userType[0].userType=='Client Admin'">
                <a>
                 <div class="inactive-img"   (click)="click='Disabledstaff';route()"  [ngClass]="{ 'active' : click=='Disabledstaff' }">
                    <div><img src="../../assets/images/staff-list.svg" height="34px"></div>
                    <span>Disabled Staff</span>
                </div>
                </a>
            </li>
            <li *ngIf=" this.storedData.userType[0].userType=='Client Admin'">
                <a>
                  <div class="inactive-img" (click)="click='products';route()"  [ngClass]="{ 'active' : click=='products' }">
                    <div><img src="../../assets/images/products.svg"></div>
                    <span>Products</span>
                   </div>
                </a>
            </li>
            <li *ngIf=" this.storedData.userType[0].userType=='Client Admin'">
                <a>
                  <div class="inactive-img" (click)="click='fields';route()"  [ngClass]="{ 'active' : click=='fields' }">
                    <div><img src="../../assets/images/products.svg"></div>
                    <span>Fields</span>
                   </div>
                </a>
            </li>
            <li>
                <a>
                  <div class="inactive-img" (click)="click='clients';route()"  [ngClass]="{ 'active' : click=='clients' }">
                    <div><img src="../../assets/images/staff-list.svg"></div>
                    <span>Company List</span>
                   </div>
                </a>
            </li>

            <li>
                <a>
                  <div class="inactive-img" (click)="click='interested';route()"  [ngClass]="{ 'active' : click=='interested' }">
                    <div><img src="../../assets/images/staff-list.svg"></div>
                    <span>Interested</span>
                   </div>
                </a>
            </li>
            <li *ngIf=" this.storedData.userType[0].userType=='Client Admin'|| this.storedData.userType[0].userType=='Manager'">
                <a>
                  <div class="inactive-img" (click)="click='clientenrolled';route()"  [ngClass]="{ 'active' : click=='clientenrolled' }">
                    <div><img src="../../assets/images/staff-list.svg"></div>
                    <span>Client Enrolled</span>
                   </div>
                </a>
            </li>

            <!-- <li>
                <a>
                  <div class="inactive-img" (click)="click='rejected';route()"  [ngClass]="{ 'active' : click=='rejected' }">
                    <div><img src="../../assets/images/staff-list.svg"></div>
                    <span>Rejected List</span>
                   </div>
                </a>
            </li> -->

            <li>
                <a>
                  <div class="inactive-img" (click)="click='reminder';route()"  [ngClass]="{ 'active' : click=='reminder' }">
                    <div><img src="../../assets/images/staff-list.svg"></div>
                    <span>Reminder List</span>
                   </div>
                </a>
            </li>

            <li *ngIf=" this.storedData.userType[0].userType=='Client Admin'">
                <a>
                  <div class="inactive-img" (click)="click='report';route()"  [ngClass]="{ 'active' : click=='report' }">
                    <div><img src="../../assets/images/reports.svg"></div>
                    <span>Reports</span>
                   </div>
                </a>
            </li>
            <!-- <li >
                <a>
                  <div class="inactive-img" (click)="click='mailSend';route()"  [ngClass]="{ 'active' : click=='mailSend' }">
                    <div><img src="../../assets/images/reports.svg"></div>
                    <span>Mail send</span>
                   </div>
                </a>
            </li>
            <li >
                <a>
                  <div class="inactive-img" (click)="click='quoteSend';route()"  [ngClass]="{ 'active' : click=='quoteSend' }">
                    <div><img src="../../assets/images/reports.svg"></div>
                    <span>Quote send</span>
                   </div>
                </a>
            </li> -->
            <li >
                <a>
                  <div class="inactive-img" (click)="click='disable';route()"  [ngClass]="{ 'active' : click=='disable' }">
                    <div><img src="../../assets/images/reports.svg"></div>
                    <span>Disabled</span>
                   </div>
                </a>
            </li>
        </ul>


<div class="last-icon">

    <div class="sec-two"  (click)="this.router.navigate(['/works'])" *ngIf="this.storedData.userType[0].userType!='Client Admin'">
        <div class="log-div"><img src="../../assets/images/punchin-img.png"></div>
        <p >Punch In/Out
    </p>


        </div>  
<div class="sec-one" (click)="profile()">
<img [src]="this.storedData.photo?this.storedData.photo:'../../../../assets/images/user1.jpeg'">
<div class="user-sec">
<p style="width: 85px;font-size: 11px;">{{this.storedData.name}}</p>
<span>{{ this.storedData.userType[0].userType}}</span>
</div>
</div>
<div class="sec-two"  (click)="router.navigate(['/switch-usertype'])">
    <div class="log-div"><img src="../../assets/images/logout.png"></div>
    <p>Switch user</p>
    </div>
<div class="sec-two"  (click)="logOut()">
<div class="log-div"><img src="../../assets/images/logout.png"></div>
<p>Logout</p>
</div>
</div>


    </div>
</div>
</body>