import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { LoginService } from '../login/login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  storedData:any={}
  staffForm!: FormGroup

  newPassword:any=''
  newPasswordFlag:any=false
  edit:any=false
  token:any
  userTypes:any=[]
  selectedImage:any
  constructor(private formBuilder: FormBuilder,private loginService:LoginService,private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token');

    this.storedData = JSON.parse(localStorage.getItem("user") || '{}');


    this.listUserType()
console.log("ss", this.storedData);
this.staffForm = this.formBuilder.group({
  name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
  phoneNumber: ['', [Validators.required,]],
  whatsAppNumber: ['', [Validators.required,]],
  address: ['', [Validators.required,]],
  email: ['', [Validators.required,]],
  userName: ['', [Validators.required,]],
  photo:['',[Validators.required]],
   coverImage:[''],
  userType:['',[Validators.required]],

})
this.storedData.coverImage? this.staffForm.get('coverImage')?.patchValue(this.storedData.coverImage):'';
this.storedData.photo?this.staffForm.get('photo')?.patchValue(this.storedData.photo):'';


  }
  listUserType(){
    this.loginService.listUserType(this.token)
    .subscribe((response: any) => {
      if (response.status === 1) {
              this.userTypes =response.data
  
              this.userTypes =   this.userTypes.filter((userType:any) => userType.userType !== 'Super Admin');

  
      }
    })
   
  }
  upload(key:any,data:any){
    // console.log(data,key);
    
        this.loginService.UploadMultiple(data.target.files, this.token)
        .subscribe((response: any) => {
            if (response.status === 1) {
    
              Swal.fire("Uploaded")
              if (key == "staffLogo") {
                this.staffForm.get('photo')?.patchValue(response.data[0]);
    
              }
              else if(key=='staffCoverLogo'){
                this.staffForm.get('coverImage')?.patchValue(response.data[0]);
    
              }
           
            
            
            
            
            
            }})
    
    
    
    
    
    
      }
       
  saveImage(path:any){
    console.log("path",path);
    
    this.selectedImage = path;

    
    this.staffForm.get('coverImage')?.patchValue(path);
console.log(this.staffForm.value);
this.cdr.detectChanges(); 

  }
  update(){


    this.staffForm.value.userId=this.storedData._id
    this.staffForm.value.userName=this.storedData.userName
    // delete this.staffForm.value.password
    this.loginService.updateStaff(this.token,this.staffForm.value)
            .subscribe((response: any) => {
              if (response.status&&response.status === 1) {
                Swal.fire("Success")
                localStorage.setItem("user", JSON.stringify(response.data))
                this.storedData = JSON.parse(localStorage.getItem("user") || '{}');
                 this.edit=false
            }else{
              Swal.fire("Failed")


            }
            })

  }



}
