import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  click:any='dashboard'
  popUp:any
  token:any
  storedData:any={}
  constructor(private router:Router,private loginService:LoginService) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    this.storedData = JSON.parse(localStorage.getItem("user") || '{}');

  }
  logOut(){

    let storedData = JSON.parse(localStorage.getItem("user") || '{}');


let data = {
  "userId": storedData._id || null
};
    this.loginService.logOut(data,this.token).subscribe((response: any) => {
      if (response.status == 1) {

        this.router.navigate(["/login"])



        }
      })



this.router.navigate(["/login"])

  }
  profile(){
    this.router.navigate(["/profile"])

  }
  route(){
    if(this.click=='agency'
    ){
      this.router.navigate(["/agency"])

    }
    else   if(this.click=='products'
    ){
      this.router.navigate(["/product-list"])

    }
    else   if(this.click=='dashboard'
    ){
      this.router.navigate(["/main-dashboard"])

    }
     else  if(this.click=='staff'
    ){
      this.router.navigate(["/staff-list"])

    }

    else  if(this.click=='clients'
    ){
      this.router.navigate(["/client-list"])

    }
    else  if(this.click=='report'
    ){
      this.router.navigate(["/report"])

    }
  }

}
