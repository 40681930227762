<body>


  <div class="img-log">
      <img src="../../assets/images/log-marketingApp (1).png">
  </div>
  <div class="clean-login">
      
    <form>
     <div class="f-width">
      <h1 class="heading">
          Welcome..
      </h1><h2>Back..!</h2>
      <p>Please enter the <span>login</span> details</p>
    </div> 
    
    <form>
      <form [formGroup]="loginForm">
          
      <div class="form1">
          <div class="form-group">
            <label>User name</label>
            <input type="text" class="form-control" formControlName="userName"  placeholder="Type here">
          </div>
          
          <div class="form-group icon-log">
              <label>Password</label>
              <!-- <input type="password" class="form-control" formControlName="password" placeholder="Type here"> -->
              <input type="text" class="form-control" required spellcheck="false" [type]="show ? 'text' : 'password'"
                        formControlName="password">
              <a (click)="password()"><img src="../../assets/images/eye-open (1).png"></a>
              <!-- <a class="hide-password"><img [ngClass]="incorrectPass==false?'im-img':'ic-img'"
                [src]="show?'../assets/images/Eye-open.svg':'../../assets/images/eye" (click)="password()" ></a> -->
            </div>
            <p class="forget-1" (click)="forgot=true">Forgot password? </p>
       </div>  
      <div class="btn-div">
          <button type="submit" class="btn-primary" (click)="onSubmit()">Login</button>
      </div>
        </form>
    </form>
  
    <div class="poster">
      <div class="log">
          <img src="../../assets/images/log-login (1).png">
      </div>
    </div>
   </form>
  </div>
  
  
   <!--Popup start - Forgot password? -->
   
   <div id="" class="overlay" *ngIf="forgot">
      <div class="popup-ins">
          <div class="ins-f" id="ins-f1">
          <h2>Forgot password?</h2>
             
              <form class="staff-form mt-2">
                  <div class="row">
                      <div class="col-lg-12 col-xs-12 col-sm-6">
                  <div class="form-group">
                    <label>Enter Your Email ID*</label>
                    <input type="text" class="form-control" placeholder="Type here"  [(ngModel)]="forgotData"  [ngModelOptions]="{standalone: true}">
                  </div>
                  </div>
              </div>
              </form>
      </div>
    
          <div class="ins-footer">
      <button class="btn-sec" (click)="close()">Back</button>
      <button class="btn-prime" (click)="SendNewPassword();forgot=false;">Send new password</button>
      </div>
    
      </div>
    </div> 
    
     
     <!--Popup end-->
  
   <!--Popup start -temporary password-->
  
   <div id="" class="overlay" *ngIf="newPasswordFlag">
      <div class="popup-ins" id="popup-ins1">
          <div class="ins-f" id="ins-f1">
          <h2>Enter your temporary password</h2>
             
              <form class="staff-form mt-2">
                  <div class="row">
                      <div class="col-lg-12 col-xs-12 col-sm-6">
                  <div class="form-group">
                    <label>Temporary password  sent to your mail “{{forgotData}}”</label>
                    <input type="text" class="form-control" placeholder="Type here"  [(ngModel)]="newPassword"  [ngModelOptions]="{standalone: true}">
                  </div>
                  </div>
              </div>
              </form>
      </div>
    
          <div class="ins-footer">
      <button class="btn-sec" (click)="newPasswordFlag=false">Back</button>
      <button class="btn-prime" (click)="newPasswordFlag=false">Login</button>
      </div>
    
      </div>
    </div> 
   
     
     <!--Popup end-->
  
  
  <footer class="footer-main">
    <img src="../../assets/images/accruon-footer (2).png">
    <!-- Powered by <span>Accruon Technologies Pvt Ltd</span> -->
  </footer>
  
  
 
  
  
  
  
  </body>