import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-swich-usertype',
  templateUrl: './swich-usertype.component.html',
  styleUrls: ['./swich-usertype.component.css']
})
export class SwichUsertypeComponent implements OnInit {
token:any
storedData:any
selectedUserType:any
userTypeArray:any=[]
  constructor(private loginService:LoginService,private router:Router) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    this.storedData= JSON.parse(localStorage.getItem("user") || '{}');
    console.log(this.storedData);
    this.listSingleUser()
    
  }
  listSingleUser(){


    let id = this.storedData._id 
    // this.selectedAgencyDetails = this.agencyDetails.find((each: any) => {
    //   return each.id === this.selectedAgencyId;
    // });
    
 
            this.loginService.listSingleStaff(this.token,id)
            .subscribe((response: any) => {
              if (response.status === 1) {
                this.userTypeArray=response.data[0].userType
                 this.storedData.userType= this.userTypeArray
                 this.userTypeArray =  this.userTypeArray.filter((user: any) =>
                  ['Client Admin', 'Backend Staff', 'Supervisor', 'Manager', 'Tele Caller'].includes(user.userType)
                );
                
                console.log(this.storedData);
                          // if(response.data.userType[0].userType=='Client Admin'){
                            this.storedData.userTypeStrings =  this.userTypeArray.map((user:any) => user.userType);
          // this.userTypeArrays = response.data.userType.map((user:any) => user.userType);
          // response.data.userTypeIds=this.userTypeArrays
              }
              })


  }


  SwitchUser(){
    this.storedData.userType = this.storedData.userType.filter(
      (type: any) => type._id === this.selectedUserType
    );
    console.log(this.storedData);
    // localStorage.removeItem('tab');
    localStorage.setItem('tab','All Cases');

    localStorage.setItem("user", JSON.stringify(this.storedData))
    this.router.navigate(["/work-page"])

  }
}
