// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 API_BASE_URL: "https://marketingclientapi.accruontechnologies.co.in/api/admin/",
 firebase: {
  apiKey: "AIzaSyDYxU1qjXyKNF-xhicVBaXXmNVmiCi_tKE",
  authDomain: "sgms-prod.firebaseapp.com",
  projectId: "sgms-prod",
  storageBucket: "sgms-prod.appspot.com",
  messagingSenderId: "141718463067",
  appId: "1:141718463067:web:7520253a2c80a611e0557e",
  measurementId: "G-JK1MDQDH2B",
  vapidkey:"BCNBlFdHLAMyQYDp8uqmO_nxTzR8quN9fAT8n8-0T3ZN7PSNrZ19yXYi3F82PCL85ltRagBKBEQtyECiTvBBMhg"
},
GOOGLE_API_KEY: 'AIzaSyBeI2Ag4i8guWeP1rjvkhmAT01RJ563Guk',

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
