<div class="main-wrapper">
    <div class="main-content">
        <header>

            <div class="head-line">

                <p>Client List</p>
            </div>
            <div class="user-wrapper">
                <p>{{storedData.agencyRefId.agencyName}}</p>

                <div>
                    <img class="log1" [src]="this.storedData.agencyRefId.logo?this.storedData.agencyRefId.logo:'../../../../assets/images/user1.jpeg'">
                </div>
            </div>
        </header>

        <main class="main-2">

            <div class="container">
                <div class="projects">


                    <div class="two-screen">
                        <!---sec sidebar-->

                        <div class="second-side">
                            <p class="table-heading">Client list     </p>
                            <div class="fill-sea">
                                <div class="search-m">
                                    <input type="text" id="searchQueryInput1"  placeholder="Search clients..." [(ngModel)]="searchQuery">
                                    <button type="submit" id="searchQuerySubmit">
                                        <!-- <svg viewBox="0 0 24 24"
                                            style="width: 24px; height: 24px;">
                                            <path fill="#666666"
                                                d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z">
                                            </path>
                                        </svg> -->
                                    </button>
                                </div>
                                <!-- <img src="../../assets/images/filter.svg" class="filter-img"> -->
                                <div class="filter-img-container">
                                    <img src="../../assets/images/filter.svg" class="filter-img">

                                </div>
                            </div>


                            <div class="side-content">
                                <div *ngFor="let each of cientDetails | appFilter:searchQuery">

                                    <div (click)="clientDetailswithAuthorisedPersonFlag=true"
                                        [ngClass]="{'main-box': isSelected(each._id), 'main-box2': !isSelected(each._id),'alternate-color': true}"
                                        (click)="selectClient(each._id)">
                                        <div
                                            [ngClass]="{'second-list': isSelected(each._id), 'second-list2': !isSelected(each._id)}">
                                            <div *ngIf="each.clientLogo" class="v-img"><img [src]="each.clientLogo">
                                            </div>
                                            <div>
                                                <h6 class="name-one">{{each.clientName}}</h6>
                                                <span *ngIf="each.clientAddress">{{each.clientAddress}}</span><br>
                                                <span *ngIf="each.createdBy">Created by:
                                                    {{each.createdBy.name}}</span><br>
                                                <span *ngIf="each.createdBy">Created date:
                                                    {{each.createdBy.name}}</span>
                                            </div>
                                        </div>
                                        <button class="light-b-status">Status</button>
                                        <img src="../../assets/images/right-arrow.png">
                                    </div>
                                </div>

                            </div>



                            <div class="footer-section1">
                                <button
                                    (click)="clientDetailsWithhistoryFlag=false;clientDetailswithAuthorisedPersonFlag=false;editClientFlag=false;listProducts(); addClientFlag=true;clientForm.reset();work_location={};productArray=[]"><img
                                        src="../../assets/images/plus-blue.png">Add New Client</button>

                            </div>
                            <div class="footer-section2">

                                <img src="../../assets/images/accruon-footer (2).png">
                            </div>

                        </div>



                        <!---------------->
                        <div class="card2" *ngIf="clientDetailsWithhistoryFlag">
                            <p class="table-heading">Client details</p>
                            <div class="agency-head" id="agency-h1">
                                <div class="dis-flx">
                                    <img src="../../assets/images/user1.jpeg">

                                    <p>Facebook</p>

                                </div>
                                <div>

                                    <button class="stat-btn"><span>Edit</span>
                                        <div class="edit-status"> <img src="../../assets/images/pencil-black.svg"></div>
                                    </button>

                                    <!-- <div class="edit-icon">
                                <input type="file" id="fileInput" class="file-input2">
                                <label for="fileInput" class="file-label">
                                <img src="../../assets/images/edit.png"></label>
                            </div> -->
                                </div>
                            </div>

                            <table class="agency-table">
                                <tr>
                                    <td>
                                        <p class="name-two">Type</p>
                                        <p class="name-one">LLC</p>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="name-two">Address</p>
                                        <p class="name-one">Edappally, Ernakulam</p>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="name-two">Whatsapp</p>
                                        <p class="name-one">0989721646</p>
                                        <!-- </tr> -->
                                </tr>
                                <tr>
                                    <td>
                                        <p class="name-two">Created by</p>
                                        <p class="name-one">Johns</p>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="name-two">Country</p>
                                        <p class="name-one">India</p>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="name-two">Business details</p>
                                        <p class="name-one">There are many variations of passages of Lorem fgfd
                                            gfgdIpsum available, but the majority have suffered alteration in some form,
                                            by injected humour</p>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="content" style="justify-content: left;">

                                            <!---->
                                            <button class="btn-two"
                                                (click)="clientDetailswithAuthorisedPersonFlag=true;clientDetailsWithhistoryFlag=false">View
                                                full details</button>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <p class="table-heading">History</p>


                            <table class="agency-table" id="ag-t1">
                                <tr>
                                    <td>
                                        <div class="date-time">
                                            <div>
                                                <p class="table-heading">First Visit</p>
                                                <p class="name-two">Remarks</p>
                                            </div>
                                            <div class="d1-t">
                                                <p><span>24/07/2024</span><img
                                                        src="../../assets/images/calender-bl.svg"></p>
                                                <p><Span>12:00 pm</Span><img src="../../assets/images/clock-bl.svg"></p>
                                            </div>
                                        </div>

                                        <p class="name-one">There are many variations of passages of Lorem Ipsumgfdg
                                            gddfgdf available, but the majority have suffered alteration in some form,
                                            by injected humour</p>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="date-time">
                                            <div>

                                                <p class="name-two">Done by</p>
                                            </div>

                                        </div>
                                        <p class="name-one">Allex Allex</p>
                                        <!-- </tr> -->
                                </tr>
                            </table>
                            <table class="agency-table">

                                <tr>
                                    <td>
                                        <div class="date-time">
                                            <div>
                                                <p class="table-heading">Created</p>
                                                <p class="name-two">Done by</p>
                                            </div>
                                            <div class="d1-t">
                                                <p><span>24/07/2024</span><img
                                                        src="../../assets/images/calender-bl.svg"></p>
                                                <p><Span>12:00 pm</Span><img src="../../assets/images/clock-bl.svg"></p>
                                            </div>
                                        </div>
                                        <p class="name-one">Allex Allex</p>
                                        <!-- </tr> -->
                                </tr>
                            </table>


                        </div>













                        <div class="card2" *ngIf="clientDetailswithAuthorisedPersonFlag">
                            <p class="table-heading">Client details</p>
                            <div class="agency-head" id="agency-h1"  [attr.style]="'background-image: url(' + (selectedClientDetails.clientBackground ? selectedClientDetails.clientBackground : '../../assets/images/Frame 427319013.jpg') + '); background-size: cover;'">

                                <div class="dis-flx">
                                    <img *ngIf="selectedClientDetails.clientLogo"
                                        [src]="selectedClientDetails.clientLogo">
                                    <p>{{selectedClientDetails.clientName}} </p>

                                </div>
                                <div>

                                    <!-- <button class="stat-btn"><span>Edit</span>
                                        <div class="edit-status"> <img src="../../assets/images/pencil-black.svg"></div>
                                    </button> -->


                                </div>
                            </div>

                            <table class="agency-table">
                                <tr>
                                    <td>
                                        <p class="name-two">Type</p>
                                        <p class="name-one">{{selectedClientDetails.clientType}}</p>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="name-two">Products</p>
                                        <div *ngFor="let i of  selectedClientDetails.clientProductsList">
                                            <p class="name-one">{{i.name}}</p>

                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="name-two">Address</p>
                                        <p class="name-one">{{selectedClientDetails.clientAddress}}</p>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="name-two">Created by</p>
                                        <p class="name-one">{{selectedClientDetails.clientAddress}}</p>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="name-two">Country</p>
                                        <p class="name-one">{{selectedClientDetails.clientCountry}}</p>
                                </tr>

                                <tr>
                                    <td>
                                        <p class="name-two">Business details</p>
                                        <p class="name-one">{{selectedClientDetails.clientBusinessDetails}}</p>
                                </tr>

                            </table>



                            <p class="table-heading">Google map location</p>


                            <table class="agency-table" id="ag-t1">
                                <tr>
                                    <td class="dot-width">
                                        <img src="../../assets/images/google-map.png">
                                    </td>
                                    <td>

                                        <div>
                                            <p class="name-two">Location</p>
                                            <p class="name-one" *ngIf="selectedClientDetails.clientGMap">
                                                {{selectedClientDetails.clientGMap.address}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <!-- <div class="content">
                                            <button class="btn-two">View Google Map</button>
                                        </div> -->
                                    </td>

                                </tr>

                            </table>

                            <p class="table-heading">Photos</p>


                            <div class="container11" *ngIf="selectedClientDetails.clientPhotos">
                                <img *ngIf="selectedClientDetails.clientPhotos.length>0"
                                    [src]="selectedClientDetails.clientPhotos[0]" class="banner-sec main-image"
                                    alt="Main Image">
                                <div class="grid-imgs">
                                    <img *ngIf="selectedClientDetails.clientPhotos[1]"
                                        [src]="selectedClientDetails.clientPhotos[1]">
                                    <img *ngIf="selectedClientDetails.clientPhotos[2]"
                                        [src]="selectedClientDetails.clientPhotos[2]">
                                    <div class="more-img" *ngIf="selectedClientDetails.clientPhotos.length > 3"></div>
                                    <div class="centered" *ngIf="selectedClientDetails.clientPhotos.length > 3">
                                        +{{selectedClientDetails.clientPhotos.length-3}} Photos</div>
                                </div>
                            </div>

                            <p class="b-head-bl">Authorized persons</p>
                            <div *ngFor="let owner of selectedClientDetails.clientOwnerDetails  let i=index">
                                <p class="n-font">Person {{i+1}}</p>
                                <table class="agency-table">
                                    <tr>
                                        <td>
                                            <p class="name-two">Name</p>
                                            <p class="name-font">{{owner.ownerName}}</p>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p class="name-two">Designation</p>
                                            <p class="name-one">{{owner.Designation}}</p>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p class="name-two">Address</p>
                                            <p class="name-one">{{owner.address}}</p>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p class="name-two">Phone</p>
                                            <p class="name-one">{{owner.ownerPhone}}</p>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p class="name-two">Whatsapp</p>
                                            <p class="name-one">{{owner.whatsappNumber}}</p>
                                    </tr>

                                    <tr>
                                        <td>
                                            <p class="name-two">Email ID</p>
                                            <p class="name-one">{{owner.ownerEmail}}</p>
                                    </tr>

                                </table>
                            </div>
                            <!-- <p class="n-font">Person 1</p>
                            <table class="agency-table">
                                <tr>
                                    <td>
                                        <p class="name-two">Name</p>
                                        <p class="name-font">Lene Hammad</p>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="name-two">Designation</p>
                                        <p class="name-one">Manager</p>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="name-two">Address</p>
                                        <p class="name-one">Edappally, Ernakulam</p>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="name-two">9988776655</p>
                                        <p class="name-one">9988776655</p>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="name-two">Whatsapp</p>
                                        <p class="name-one">9988776655</p>
                                </tr>

                                <tr>
                                    <td>
                                        <p class="name-two">Email ID</p>
                                        <p class="name-one">lenehammad@gmail.com</p>
                                </tr>

                            </table> -->

                            <div class="content">
                                <button class="btn-two" (click)="clientDetailsWithhistoryFlag=false;clientDetailswithAuthorisedPersonFlag=false;addClientFlag=false;
                                editClientFlag=true;setData()">
                                    Edit client details</button>
                               
                            </div>

                        </div>




                        <div class="card2" *ngIf="addClientFlag">
                         
                            <p class="table-heading">Client details </p>
                            <p class="sub-para">Please fill the details </p>
                            
                            <div class="agency-head" id="agency-h1"
                                [attr.style]="'background-image: url(' + (clientForm.value.clientBackground ? clientForm.value.clientBackground : '../../assets/images/Frame 427319013.jpg') + '); background-size: cover;'">

                                <input type="file" id="fileInputclientlogo" class="file-input-bg-edit"
                                    (change)="upload('clientLogo',$event)">
                                <label for="fileInputclientlogo" class="file-label">
                                    <img alt="User Image"
                                        src="{{clientForm.value.clientLogo ? clientForm.value.clientLogo:'../../../../assets/images/user-no.jpg'}}"
                                        src="../../assets/images/user-no.jpg">
                                    <img src="../../assets/images/plus-black.png" class="plus-b"></label>


                                <div>

                                    <div class="edit-icon">
                                        <input type="file" id="clientCoverImage" class="file-input2"
                                            (change)="upload('clientCoverImage',$event)">
                                        <label for="clientCoverImage" class="file-label">
                                            <img src="../../assets/images/edit.png"></label>
                                    </div>
                                </div>
                              
                            </div>

                            <form class="staff-form" [formGroup]="clientForm">
                                <div class="row">
                                    <div class="col-lg-12 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label>Name*</label>
                                            <input type="text" class="form-control" formControlName="clientName"
                                                placeholder="Type here">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label>Type*</label>
                                            <input type="text" class="form-control" formControlName="clientType"
                                                placeholder="Type here">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label>Country*</label>
                                            <input type="text" class="form-control" formControlName="clientCountry"
                                            placeholder="Type here">
                                           
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label>Products*</label>
                                            <ng-multiselect-dropdown [settings]="dropdownSettings" name="product"  [disabled]="disabled"
                                            [data]="serviceFormats"  [(ngModel)]="selectedItems" formControlName="clientProductsList" >
                                           </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label>Address*</label>
                                            <textarea class="form-control" placeholder="Type here"
                                                formControlName="clientAddress" style="    height: 99px;"></textarea>

                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-xs-12 col-sm-6">

                                        <div class="form-group img-slide">

                                            <div class="image-upload">
                                                <input type="file" id="fileInputClient" class="file-input-bg-edit"
                                                    (change)="upload('clientPhotos',$event)">
                                                <label for="fileInputClient" class="file-label">
                                                    <img alt="User Image" src="../../assets/images/no-img.jpg"
                                                        class="user-img">
                                                    <img src="../../assets/images/plus-black.png" class="plus-img">
                                                </label>
                                            </div>
                                            <div *ngFor="let data of productArray">
                                                <img [src]="data" style="width: 70px; height: 79px;">
                                            </div>



                                            <!-- <img src="../../assets/images/2.jpg" style="width: 70px !important;height: 79px!important"> -->





                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label>Business details*</label>
                                            <textarea class="form-control" formControlName="clientBusinessDetails"
                                                placeholder="Type here" style="    height: 99px;"></textarea>

                                        </div>
                                    </div>



                                </div>
                            </form>





                            <table class="agency-table mt-2" id="ag-t1">
                                <tr>
                                    <td class="dot-width">
                                        <img src="../../assets/images/google-map.png">
                                    </td>
                                    <td>

                                        <div>
                                            <p class="name-two">Location</p>
                                            <p class="name-one">{{ work_location?work_location.address:''}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="content">
                                            <button class="btn-two" (click)="showMaps()">Add Google Map</button>
                                        </div>
                                    </td>

                                </tr>

                            </table>

                            <p class="table-heading">Authorized Person Details</p>

                            <form class="staff-form" [formGroup]="clientForm">
                                <div formArrayName="clientOwnerDetails">
                                    <div *ngFor="let each of clientOwnerDetails.controls; let i=index"
                                        [formGroupName]="i">
                                        <div class="row">
                                            <div class="col-lg-6 col-xs-12 col-sm-6">
                                                <div class="form-group">
                                                    <label>Name*</label>
                                                    <input type="text" class="form-control" formControlName="ownerName"
                                                        placeholder="Type here">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-xs-12 col-sm-6">
                                                <div class="form-group">
                                                    <label>Designation*</label>
                                                    <input type="text" class="form-control"
                                                        formControlName="Designation" placeholder="Type here">
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-xs-12 col-sm-6">
                                                <div class="form-group">
                                                    <label>Address*</label>
                                                    <textarea class="form-control" placeholder="Type here"
                                                        formControlName="address" style="    height: 99px;"></textarea>

                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-xs-12 col-sm-6 ext-padding">
                                                <div class="col-lg-12 col-xs-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label>Phone number*</label>
                                                        <input type="text" class="form-control"
                                                            formControlName="ownerPhone" placeholder="Type here">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-xs-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label>Whatsapp*</label>
                                                        <input type="text" class="form-control"
                                                            formControlName="whatsappNumber" placeholder="Type here">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-xs-12 col-sm-6">
                                                <div class="form-group">
                                                    <label>Email ID*</label>
                                                    <input type="email" class="form-control"
                                                        formControlName="ownerEmail" placeholder="Type here">
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div class="content" style="justify-content: left;" (click)="addOwnerDetail()">
                                <button class="add-n">Add new authorized person</button>
                            </div>

                            <div class="content">
                                <button class="add-n">Back</button>
                                <button class="btn-two" (click)="createClient()">Create client</button>
                            </div>

                        </div>

                        <div class="card2" *ngIf="editClientFlag">
                            <p class="table-heading">Client details</p>
                            <p class="sub-para">Please fill the details</p>
                            <div class="agency-head" id="agency-h1"
                                [attr.style]="'background-image: url(' + (clientForm.value.clientBackground ? clientForm.value.clientBackground : '../../assets/images/Frame 427319013.jpg') + '); background-size: cover;'">

                                <input type="file" id="fileInputclientlogo" class="file-input-bg-edit"
                                    (change)="upload('clientLogo',$event)">
                                <label for="fileInputclientlogo" class="file-label">
                                    <img alt="User Image"
                                        src="{{clientForm.value.clientLogo ? clientForm.value.clientLogo:'../../../../assets/images/user-no.jpg'}}"
                                        src="../../assets/images/user-no.jpg">
                                    <img src="../../assets/images/plus-black.png" class="plus-b"></label>


                                <div>

                                    <div class="edit-icon">
                                        <input type="file" id="clientCoverImage" class="file-input2"
                                            (change)="upload('clientCoverImage',$event)">
                                        <label for="clientCoverImage" class="file-label">
                                            <img src="../../assets/images/edit.png"></label>
                                    </div>
                                </div>
                            </div>

                            <form class="staff-form" [formGroup]="clientForm">
                                <div class="row">
                                    <div class="col-lg-12 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label>Name*</label>
                                            <input type="text" class="form-control" formControlName="clientName"
                                                placeholder="Type here">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label>Type*</label>
                                            <input type="text" class="form-control" formControlName="clientType"
                                                placeholder="Type here">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label>Country*</label>
                                            <input type="text" class="form-control" formControlName="clientCountry"
                                            placeholder="Type here">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label>Products*</label>
                                            <ng-multiselect-dropdown [settings]="dropdownSettings" name="product"  [disabled]="disabled"
                                            [data]="serviceFormats"   formControlName="clientProductsList" >
                                           </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label>Address*</label>
                                            <textarea class="form-control" placeholder="Type here"
                                                formControlName="clientAddress" style="    height: 99px;"></textarea>

                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-xs-12 col-sm-6">

                                        <div class="form-group img-slide">

                                            <div class="image-upload">
                                                <input type="file" id="fileInputClient" class="file-input-bg-edit"
                                                    (change)="upload('clientPhotos',$event)">
                                                <label for="fileInputClient" class="file-label">
                                                    <img alt="User Image" src="../../assets/images/no-img.jpg"
                                                        class="user-img">
                                                    <img src="../../assets/images/plus-black.png" class="plus-img">
                                                </label>
                                            </div>
                                            <div *ngFor="let data of productArray">
                                                <img [src]="data" style="width: 70px; height: 79px;">
                                            </div>



                                            <!-- <img src="../../assets/images/2.jpg" style="width: 70px !important;height: 79px!important"> -->





                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label>Business details*</label>
                                            <textarea class="form-control" formControlName="clientBusinessDetails"
                                                placeholder="Type here" style="    height: 99px;"></textarea>

                                        </div>
                                    </div>



                                </div>
                            </form>





                            <table class="agency-table mt-2" id="ag-t1">
                                <tr>
                                    <td class="dot-width">
                                        <img src="../../assets/images/google-map.png">
                                    </td>
                                    <td>

                                        <div>
                                            <p class="name-two">Location</p>
                                            <p class="name-one">{{this.clientForm.value.clientGMap.address}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="content">
                                            <button class="btn-two" (click)="showMaps()">Add Google Map</button>
                                        </div>
                                    </td>

                                </tr>

                            </table>

                            <p class="table-heading">Authorized Person Details</p>

                            <form class="staff-form" [formGroup]="clientForm">
                                <div formArrayName="clientOwnerDetails">
                                    <div *ngFor="let each of clientOwnerDetails.controls; let i=index"
                                        [formGroupName]="i">
                                        <div class="row">
                                            <div class="col-lg-6 col-xs-12 col-sm-6">
                                                <div class="form-group">
                                                    <label>Name*</label>
                                                    <input type="text" class="form-control" formControlName="ownerName"
                                                        placeholder="Type here">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-xs-12 col-sm-6">
                                                <div class="form-group">
                                                    <label>Designation*</label>
                                                    <input type="text" class="form-control"
                                                        formControlName="Designation" placeholder="Type here">
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-xs-12 col-sm-6">
                                                <div class="form-group">
                                                    <label>Address*</label>
                                                    <textarea class="form-control" placeholder="Type here"
                                                        formControlName="address" style="    height: 99px;"></textarea>

                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-xs-12 col-sm-6 ext-padding">
                                                <div class="col-lg-12 col-xs-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label>Phone number*</label>
                                                        <input type="text" class="form-control"
                                                            formControlName="ownerPhone" placeholder="Type here">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-xs-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label>Whatsapp*</label>
                                                        <input type="text" class="form-control"
                                                            formControlName="whatsappNumber" placeholder="Type here">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-xs-12 col-sm-6">
                                                <div class="form-group">
                                                    <label>Email ID*</label>
                                                    <input type="email" class="form-control"
                                                        formControlName="ownerEmail" placeholder="Type here">
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div class="content" style="justify-content: left;" (click)="addOwnerDetail()">
                                <button class="add-n">Add new authorized person</button>
                            </div>

                            <div class="content">
                                <button class="add-n" (click)="editClientFlag=false; clientDetailswithAuthorisedPersonFlag=true">Back</button>
                                <button class="btn-two" (click)="updateClient()">Update client</button>
                            </div>

                        </div>







                        <div id="show-img" class="overlay" *ngIf="showMap">
                            <div class="col-lg-6" style="background-color: #fff;">

                                <div class="form-group">
                                    <input type="text" class="form-control"  (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #agmSearch>
                                </div>


                                 <div class="form-group">
                                    <input [(ngModel)]="lat1" type="text" placeholder="Latitude" (input)="setLatitude($event)">
                                    <input [(ngModel)]="long1" type="text" placeholder="Longitude" (input)="setLongitude($event)">
                                    <!-- <button (click)="setCoordinates(lat1 ,long1)" class="btn-primary" style="margin-left: 10px;">
                                        Search by Coordinates</button> -->
                                  </div>
                                  
                                 
                        
                                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="14" style="width: 100%; height: 323px; border: 0px;">
                                    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true" (dragEnd)="markerDragEndBranch($event)">
                        
                                    </agm-marker>
                                </agm-map>           
                                <div class="content">
                                    <button class="add-n" (click)="ok()">OK</button>
                               </div>
                            </div>
                           
                            <!-- <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="14"
                                style="width: 100%; height: 323px; border: 0px;">
                                <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                                    (dragEnd)="markerDragEndBranch($event)">

                                </agm-marker>
                            </agm-map> -->
                        </div>

                        <!--Popup end-->























                    </div>

                </div>

            </div>


        </main>

    </div>
</div>