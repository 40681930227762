
    <div class="main-wrapper">
    <div class="main-content">
        <header>

            <div class="head-line">

                <p>Product List</p>
            </div>
            <div class="user-wrapper">
                <p>{{storedData.agencyRefId.agencyName}}</p>

                <div>
                    <img class="log1" [src]="this.storedData.agencyRefId.logo?this.storedData.agencyRefId.logo:'../../../../assets/images/user1.jpeg'">
                </div>
            </div>
        </header>
            
            <main class="main-2">

               <div class="container">
                <div class="projects">
                    
                    
                    <div class="two-screen">
<!---sec sidebar-->

<div class="second-side">
    <p class="table-heading">Product list</p>
    <div class="fill-sea">
    <div class="search-m"> 
        <input type="text" id="searchQueryInput1" placeholder="Search here..." value="" [(ngModel)]="searchQuery" >
        <button type="submit" id="searchQuerySubmit">
            <!-- <svg viewBox="0 0 24 24" style="width: 24px; height: 24px;">
        <path fill="#666666" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"></path>
        </svg> -->
        </button>
</div>
<!-- <img src="../../assets/images/filter.svg" class="filter-img"> -->
<div class="filter-img-container">
    <img src="../../assets/images/filter.svg" class="filter-img">
   
</div>
</div>


<div class="side-content">
    <div *ngFor="let product of productDetails | appFilter:searchQuery">
<div class="product-side2 product-c product-side-active"   (click)="viewProducts=true"     [ngClass]="{'product-side-active': isSelected(product._id), 'product-side': isSelected(product._id)}"   (click)="selectProduct(product._id)">
    <div class="product-list">
    <div class="v-img"><img  [src]="product.logo"></div>
    <div><h6>{{product.name}}</h6>
        <span>{{product.description}}...</span>
        </div>
        </div>
        <!-- <img src="../../assets/images/right-arrow.png"> -->
    </div>
    
    </div>
  
    

    </div>



    <div class="footer-section1" (click)="viewProducts=false;addProductFlag=true;productForm.reset()">
        <button ><img src="../../assets/images/plus-blue.png" >Add New Product</button>
      
     </div>
     <div class="footer-section2">

        <img src="../../assets/images/accruon-footer (2).png">
     </div>
    
    </div>



<!---------------->
                    <div class="card2" *ngIf="viewProducts">
                        <p class="table-heading">Product details</p> 

                       
                        <div class="agency-head" [attr.style]="'background-image: url(' + (selectedProductfDetails.coverImage ? selectedProductfDetails.coverImage : '../../assets/images/Frame 427319013.jpg') + '); background-size: cover;'">
                            <img src={{selectedProductfDetails.logo}}>
                            <div>
                                <p>{{selectedProductfDetails.name}} </p>
                             
                            </div>
                         
                        </div>
                        <p class="sub-head">Description</p>
                       

                        <p class="paragraph-note">
                            {{selectedProductfDetails.description}}
                        </p>



                        <div class="content">
                            <button class="add-n" (click)="disableProduct()">Disable item</button>
                            <button class="btn-two"  (click)="editProduct();addProductFlag=false;editProductFlag=true">Edit description</button>
                        </div>
                
                    

                        </div>



                        <div class="card2" *ngIf="addProductFlag">
                            <p class="table-heading">Product Creation</p> 
                            <p class="sub-para">Please fill the details</p>  
                            <div class="agency-head" id="ext-agency2"   [attr.style]="'background-image: url(' + (productForm.value.coverImage ? productForm.value.coverImage : '../../assets/images/Frame 427319013.jpg') + '); background-size: cover;'">
                                <div class="image-upload">
                                    <input type="file" id="fileInput" class="file-input-bg-edit" (change)="upload('productLogo',$event)">
                                    <label for="fileInput" class="file-label">
                                     <img alt="User Image" src="{{productForm.value.logo ? productForm.value.logo:'../../../../assets/images/user-no.jpg'}}">
                                        <img src="../../assets/images/edit.png" class="plus-b"></label>
                            </div>
                            
                                                            <div class="main-imglist">
                                                                <div class="img-list">
                                                                 <img src="../../assets/images/1.jpg"  (click)="saveImage('../../assets/images/1.jpg')" class=" small-icon"  [ngClass]="{ 'active-icon': productForm.value.coverImage=='../../assets/images/1.jpg'}">
                                                                 <img src="../../assets/images/2.jpg"  (click)="saveImage('../../assets/images/2.jpg')" class="small-icon" [ngClass]="{ 'active-icon': productForm.value.coverImage=='../../assets/images/2.jpg'}">
                                                                 <img src="../../assets/images/3.jpg"  (click)="saveImage('../../assets/images/3.jpg')"class="small-icon" [ngClass]="{ 'active-icon': productForm.value.coverImage=='../../assets/images/3.jpg'}">
                                                                 <img src="../../assets/images/4.jpg"  (click)="saveImage('../../assets/images/4.jpg')" class="small-icon" [ngClass]="{ 'active-icon': productForm.value.coverImage=='../../assets/images/4.jpg'}">
                                                                 <img src="../../assets/images/5.jpg"  (click)="saveImage('../../assets/images/5.jpg')" class="small-icon" [ngClass]="{ 'active-icon': productForm.value.coverImage=='../../assets/images/5.jpg'}">
                                                                 <img src="../../assets/images/6.jpg"  (click)="saveImage('../../assets/images/6.jpg')" class="small-icon" >
                                                               </div>
                                                               <img src="../../assets/images/close-black.svg" class="close-b">
                                                               </div>
                                                     
                                                     </div>
                             <form class="staff-form" [formGroup]="productForm">
                                <div class="row">
                                    <div class="col-lg-12 col-xs-12 col-sm-6">
                                <div class="form-group">
                                  <label>Name*</label>
                                  <input type="text" class="form-control" placeholder="Type here" formControlName="name">
                                </div>
                                </div>
                               
                                <div class="col-lg-12 col-xs-12 col-sm-6">
                                    <div class="form-group">
                                      <label>Description*</label>
                                      <textarea class="form-control" placeholder="Type here" style="height: 190px;" formControlName="description"></textarea>
                                    
                                    </div>
                                    </div>
                         
                                  
                                    </div>
                              </form>
    
    
    
                            <div class="content">
                                <button class="add-n" (click)="addProductFlag=false;editProductFlag=false;viewProducts=false">Back</button>
                                <!---->
                                <button class="btn-two" (click)="productCrud('Add')">Create product</button>
                            </div>
                    
                        
    
                            </div>




                            <div class="card2" *ngIf="editProductFlag">
                                <p class="table-heading">Edit Product</p> 
                                <p class="sub-para"></p>  
                                <div class="agency-head" id="ext-agency2"   [attr.style]="'background-image: url(' + (productForm.value.coverImage ? productForm.value.coverImage : '../../assets/images/Frame 427319013.jpg') + '); background-size: cover;'">
                                    <div class="image-upload">
                                        <input type="file" id="fileInput" class="file-input-bg-edit" (change)="upload('productLogo',$event)">
                                        <label for="fileInput" class="file-label">
                                         <img alt="User Image" src="{{productForm.value.logo ? productForm.value.logo:'../../../../assets/images/user-no.jpg'}}">
                                            <img src="../../assets/images/edit.png" class="plus-b"></label>
                                </div>
                               
                                                                <div class="main-imglist">
                                                                    <div class="img-list">
                                                                     <img src="../../assets/images/1.jpg"  (click)="saveImage('../../assets/images/1.jpg')" class=" small-icon"  [ngClass]="{ 'active-icon': productForm.value.coverImage=='../../assets/images/1.jpg'}">
                                                                     <img src="../../assets/images/2.jpg"  (click)="saveImage('../../assets/images/2.jpg')" class="small-icon" [ngClass]="{ 'active-icon': productForm.value.coverImage=='../../assets/images/2.jpg'}">
                                                                     <img src="../../assets/images/3.jpg"  (click)="saveImage('../../assets/images/3.jpg')"class="small-icon" [ngClass]="{ 'active-icon': productForm.value.coverImage=='../../assets/images/3.jpg'}">
                                                                     <img src="../../assets/images/4.jpg"  (click)="saveImage('../../assets/images/4.jpg')" class="small-icon" [ngClass]="{ 'active-icon': productForm.value.coverImage=='../../assets/images/4.jpg'}">
                                                                     <img src="../../assets/images/5.jpg"  (click)="saveImage('../../assets/images/5.jpg')" class="small-icon" [ngClass]="{ 'active-icon': productForm.value.coverImage=='../../assets/images/5.jpg'}">
                                                                     <img src="../../assets/images/6.jpg"  (click)="saveImage('../../assets/images/6.jpg')" class="small-icon" >
                                                                   </div>
                                                                   <img src="../../assets/images/close-black.svg" class="close-b">
                                                                   </div>
                                                         
                                                         </div>
                                 <form class="staff-form" [formGroup]="productForm">
                                    <div class="row">
                                        <div class="col-lg-12 col-xs-12 col-sm-6">
                                    <div class="form-group">
                                      <label>Name*</label>
                                      <input type="text" class="form-control" placeholder="Type here"   formControlName="name">
                                    </div>
                                    </div>
                                   
                                    <div class="col-lg-12 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                          <label>Description*</label>
                                          <textarea class="form-control" placeholder="Type here" style="height: 190px;"  formControlName="description"></textarea>
                                        
                                        </div>
                                        </div>
                             
                                      
                                        </div>
                                  </form>
        
        
        
                                <div class="content">
                                    <button class="add-n" (click)="editProductFlag=false;addProductFlag=false;viewProducts=true">Back</button>
                                    <!---->
                                    <button class="btn-two" (click)="productCrud('Edit')">Update product</button>
                                </div>
                        
                            
        
                                </div>
    

















       </div>                 
                 
                    </div>
                   
            </div>

    
            </main>
   
    </div>
    </div>


