import { Component, OnInit,ChangeDetectorRef } from '@angular/core';

import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { LoginService } from '../login/login.service';
import Swal from 'sweetalert2';
import { FilterPipe } from 'src/pipes/filter.pipe';
// import Swal from 'sweetalert2';

@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: ['./staff-list.component.css']
})
export class StaffListComponent implements OnInit {
  addStaff:any=false
  viewStaff:any=false
  staffForm!: FormGroup
  token:any
  userTypes:any
  selectedStaffId:any
  selectedImage:any='../../assets/images/pic1.jpg'
  selectedStaffDetails:any={}
  staffDetailsFlag:any
  editStaff:any=false
  staffDetails:any=[]
  searchQuery:any=''
  storedData:any={}
  productDetails:any=[]
  dropdownSettings:any={}
  dropdownproductSettings:any={}
  ShowFilter:any=false
  constructor(private formBuilder: FormBuilder,private loginService:LoginService,private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  

    this.token = localStorage.getItem('token');
    this.storedData = JSON.parse(localStorage.getItem("user") || '{}');

     this.listUserType()
    this.listStaffs()
    this.listProducts()
    this.staffForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      phoneNumber: ['', [Validators.required,]],
      whatsAppNumber: ['', [Validators.required,]],
      address: ['', [Validators.required,]],
      email: ['', [Validators.required,]],
      products:['', [Validators.required,]],
      userName: ['', [Validators.required,]],
      password: ['', [Validators.required,]],
      photo:['',[Validators.required]],
      coverImage:[''],
      userType:['',[Validators.required]],
      // gallery:[false]

    })


    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'userType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: this.ShowFilter
    };
    this.dropdownproductSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: this.ShowFilter
    };

  }


  listStaffs(){

    this.loginService.listStaffs(this.token)
    .subscribe((response: any) => {
      if (response.status === 1) {
              this.staffDetails =response.data
              this.selectedStaffId=this.staffDetails[0]._id
              this.selectStaff(this.staffDetails[0]._id)
             
            //   this.staffDetails =     [ {
            //     _id:"dzfdf",
            //     address: "saada",
            //     coverImage: "../../assets/images/2.jpg",
            //     email: "jibina@gmail.co",
            //     name: "jibina",
            //     password: "jibina",
            //     phoneNumber: "jibina3123",
            //     photo: "https://clean-worlds.s3.ap-south-1.amazonaws.com/cleanworld/files/Frame_26095131874.png",
            //     userName: "jibina",
            //     userType: "Executive",
            //     whatsAppNumber: "32213213"
            //   }
          
            // ]
            
         
  
      }
    })

    
  }

  listProducts(){

    this.loginService.listProducts(this.token)
    .subscribe((response: any) => {
      if (response.status === 1) {
              this.productDetails =response.data
  
            //   this.productDetails  =     [ {
            //     _id:"dzfdf",
              
            //     coverImage: "../../assets/images/2.jpg",
             
            //     name: "jibina",
               
            //     logo: "https://clean-worlds.s3.ap-south-1.amazonaws.com/cleanworld/files/Frame_26095131874.png",
            //     description: "jibina",

            //   }
          
            // ]
            
              
            }


          })
  
        
  }
  saveImage(path:any){
    this.selectedImage = path;

    
    this.staffForm.get('coverImage')?.patchValue(path);
console.log(this.staffForm.value);
this.cdr.detectChanges(); 

  }


  staffCrud(type:any){
    if(type=='Add'){
   
      console.log(this.staffForm.value);
      if(this.staffForm){

    
      // this.staffForm.value.agencyRefId=this.selectedAgencyDetails._id
      //  this.staffForm.value.userType=[this.staffForm.value.userType];

      const userTypeIds = this.staffForm.value.userType.map((type: any) => type._id);

// Extract `_id` values from `products` array
const productIds = this.staffForm.value.products.map((product: any) => product._id);

// Patch the extracted `_id` arrays back into the form
// this.staffForm.patchValue({
//   userType: userTypeIds,
//   products: productIds
// });
this.staffForm.value.userType=userTypeIds
this.staffForm.value.products=productIds


    
      this.loginService.addStaff(this.token,this.staffForm.value)
              .subscribe((response: any) => {
                if (response.status&&response.status === 1) {
                 Swal.fire("Success")
                 this.viewStaff=false
                 this.addStaff=false
                 this.editStaff=false
                 this.staffForm.reset()
                 this.listStaffs()

                 

              }else{
                 Swal.fire("Failed")
                 this.listStaffs()


              }
              })
            }else{
              Swal.fire("Fill The Required")
            }

    }
    else if(type=='Edit'){

      const userTypeIds = this.staffForm.value.userType.map((type: any) => type._id);

      // Extract `_id` values from `products` array
      const productIds = this.staffForm.value.products.map((product: any) => product._id);
      
      // Patch the extracted `_id` arrays back into the form
      this.staffForm.value.userType=userTypeIds
      this.staffForm.value.products=productIds
      this.staffForm.value.userId=this.selectedStaffDetails._id
      this.staffForm.value.userName=this.selectedStaffDetails.userName
      delete this.staffForm.value.password
      this.loginService.updateStaff(this.token,this.staffForm.value)
              .subscribe((response: any) => {
                if (response.status&&response.status === 1) {
                  Swal.fire("Success")

                  this.listStaffs()

                  this.isSelected(response.data[0]._id)
                  this.selectStaff(response.data[0]._id)

              }else{
                Swal.fire("Failed")
                this.listStaffs()


              }
              })


    }
   
    

  }

  isSelected(id: any): boolean {
    // console.log("fgfh",id);
    
    localStorage.setItem("CurentStaffId",id)
    return this.selectedStaffId === id;
   
  }

  


  selectStaff(id: any) {
    
    this.selectedStaffId = id 
    // this.selectedAgencyDetails = this.agencyDetails.find((each: any) => {
    //   return each.id === this.selectedAgencyId;
    // });
    
 
            this.loginService.listSingleStaff(this.token, this.selectedStaffId)
            .subscribe((response: any) => {
              if (response.status === 1) {
                this.selectedStaffDetails=response.data[0]

              console.log(this.selectedStaffDetails);
              
              }
              })

    // this.selectedStaffDetails= {
    //   address: "saada",
    //   coverImage: "../../assets/images/2.jpg",
    //   email: "jibina@gmail.co",
    //   name: "jibina",
    //   password: "jibina",
    //   phoneNumber: "jibina3123",
    
    //   photo: "https://clean-worlds.s3.ap-south-1.amazonaws.com/cleanworld/files/Frame_26095131874.png",
    //   userName: "jibina",
    //   userType: "Executive",
    //   whatsAppNumber: "32213213"
    // };

    this.viewStaff=true
    this.addStaff=false
    this.editStaff=false
  }
  disableStaff(){
Swal.fire({
  title: 'Disable File?',
  text: "You won't be able to revert this!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes',
  cancelButtonText: 'No'
}).then((result) => {
  if (result.isConfirmed) {

    
    let request={
      id:this.selectedStaffDetails._id,
      // userName:this.selectedStaffDetails.userName,
      // isActive:false
    }

    this.loginService.disableStaff(this.token,request)
    .subscribe((response: any) => {
      if (response.status === 1) {
        this.selectedStaffDetails={}
        this.staffDetailsFlag=false
        this.listStaffs()
        this.addStaff=false
        this.editStaff=false
        this.viewStaff=false
     }
    })


  }})




  }
  editStaffSetUp(){

        this.staffForm.get('photo')?.patchValue(this.selectedStaffDetails.photo);
        this.staffForm.get('coverImage')?.patchValue(this.selectedStaffDetails.coverImage);
        this.staffDetailsFlag=false
        this.viewStaff=false
        this.editStaff=true




  }
  listUserType(){
    this.loginService.listUserType(this.token)
    .subscribe((response: any) => {
      if (response.status === 1) {
              this.userTypes =response.data
  
              this.userTypes =   this.userTypes.filter((userType:any) => userType.userType !== 'Super Admin');

  
      }
    })
   
  }


  upload(key:any,data:any){
    // console.log(data,key);
    
        this.loginService.UploadMultiple(data.target.files, this.token)
        .subscribe((response: any) => {
            if (response.status === 1) {
    
              Swal.fire("Uploaded")
              if (key == "staffLogo") {
                this.staffForm.get('photo')?.patchValue(response.data[0]);
    
              }
              else if(key=='staffCoverLogo'){
                this.staffForm.get('coverImage')?.patchValue(response.data[0]);
    
              }
           
            
            
            
            
            
            }})
    
    
    
    
    
    
      }

}
