import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import moment from 'moment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-client-enrolled',
  templateUrl: './client-enrolled.component.html',
  styleUrls: ['./client-enrolled.component.css']
})
export class ClientEnrolledComponent implements OnInit {

  currentPage: number = 1;
   AllocationFlag:any=false
   itemsPerPage: number = 25;
   totalItems: number =0
   tableSize: number[] = [5, 10, 20, 50, 100];
   pagingConfig: any = {}
   pageNo: any;
   size: any;
   currentSortColumn:any=''
status:any='Interested'
   sortOrder: 'asc' | 'desc' = 'asc';
   sortField: string = ''; // Tracks the current field being sorted
   searchQuery:any=''
   cases:any=[]
   token:any
   storedData:any
   currentCount:any=0
  total:any=0
  selectedProducts:any=[]
  productSettings:any={}
   constructor(private loginService:LoginService,private router:Router) { this.pagingConfig = {
     itemsPerPage: this.itemsPerPage,
     currentPage: this.currentPage,
     totalItems: this.totalItems
   } }
 
   ngOnInit(): void {
     this.token = localStorage.getItem('token');
     this.storedData= JSON.parse(localStorage.getItem("user") || '{}');
     this.selectedProducts=this.storedData.products

    this.listCases()
    this.productSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      appendToBody: true, // Ensures dropdown appears above everything

    };
   }
   onTableSizeChange(event: any): void {
     this.pagingConfig.itemsPerPage = event.target.value;
     this.pagingConfig.currentPage = 1;
     this.size = event.target.value
     this.listCases()
 
   }
   onTableDataChange(event: any) {
     this.pagingConfig.currentPage = event;
     this.listCases()
   }
   listCases(){
     
 
     let req={
       searchKey:this.searchQuery,
 
       page:this.pagingConfig.currentPage,
       limit:this.pagingConfig.itemsPerPage
     }
 
 
     let body={
      status:'Client Enrolled',

     userType:this.storedData.userType[0].userType,
     products:this.selectedProducts,
     sortField:this.sortField,
     sortOrder:this.sortOrder
     }
 
 
       this.loginService.listClientsById(this.token,req,body)
                 .subscribe((response: any) => {
                   if (response.status === 1) {
                          this.cases=response.data
                          this.pagingConfig.totalItems = response.totalCount;
                           this.currentCount=response.data.length
                          this.total=response.totalCount
                   }})
   }
   ClientDetail(flag:any,obj:any){
     obj.flag=flag
     localStorage.setItem('Work',JSON.stringify(obj))
     this.router.navigate(["/add-work-page"])
 
   }

   

   sortTable(column: string): void {
    this.sortField=column
     if (this.currentSortColumn === column) {
       // Toggle the sort order if the column is already sorted
       this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
     } else {
       // Set the column to the current sort column and default to ascending
       this.currentSortColumn = column;
       this.sortOrder = 'asc';
     }
     this.listCases()
   
    //  this.cases.sort((a: any, b: any) => {
    //    let valueA = this.getValueByPath(a, column);
    //    let valueB = this.getValueByPath(b, column);
   
    //    // Handle null or undefined values
    //    if (valueA == null) return this.sortOrder === 'asc' ? 1 : -1;
    //    if (valueB == null) return this.sortOrder === 'asc' ? -1 : 1;
   
    //    // Attempt to parse dates
    //    const dateA = this.parseCustomDate(valueA);
    //    const dateB = this.parseCustomDate(valueB);
   
    //    if (dateA && dateB) {
    //      // Compare as dates
    //      if (dateA < dateB) return this.sortOrder === 'asc' ? -1 : 1;
    //      if (dateA > dateB) return this.sortOrder === 'asc' ? 1 : -1;
    //      return 0;
    //    }
   
    //    // Compare as numbers if both are numeric
    //    if (!isNaN(valueA) && !isNaN(valueB)) {
    //      valueA = Number(valueA);
    //      valueB = Number(valueB);
    //      return this.sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
    //    }
   
    //    // Compare as strings (case insensitive)
    //    valueA = String(valueA).toLowerCase();
    //    valueB = String(valueB).toLowerCase();
    //    if (valueA < valueB) return this.sortOrder === 'asc' ? -1 : 1;
    //    if (valueA > valueB) return this.sortOrder === 'asc' ? 1 : -1;
    //    return 0;
    //  });
   }

   
   parseCustomDate(dateString: string): Date | null {
    // Match DD/MM/YYYY
    const ddmmyyyyRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    const ddmmyyyyMatch = dateString.match(ddmmyyyyRegex);
    if (ddmmyyyyMatch) {
      const [_, day, month, year] = ddmmyyyyMatch.map(Number);
      return new Date(year, month - 1, day);
    }
  
    // Match YYYY-MM-DD
    const yyyymmddRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
    const yyyymmddMatch = dateString.match(yyyymmddRegex);
    if (yyyymmddMatch) {
      const [_, year, month, day] = yyyymmddMatch.map(Number);
      return new Date(year, month - 1, day);
    }
  
    return null; // Return null if no valid date format is detected
  }
  
  // Helper function to get nested values by path
  getValueByPath(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

         format(date:any){
           return moment(date).format('DD-MM-YYYY \nHH:mma');
         
         }
   
   formatDate(isoDate: string): string {
     if(isoDate){
       const date = new Date(isoDate);
       const day = String(date.getDate()).padStart(2, '0');
       const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
       const year = date.getFullYear();
       return `${day}/${month}/${year}`;
     }else{
       return ''
     }
   
   }
   disable(eachCase:any){
 
 
       Swal.fire({
         title: 'Disable File?',
         text: "You won't be able to revert this!",
         icon: 'warning',
         html: `
           <textarea id="commentTextarea" class="swal2-textarea" 
             maxlength="58" 
 
           placeholder="Enter your comments here..." style="width: 100%; height: 100px;
          margin:0px">
         `,
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes',
         preConfirm: () => {
           // Fetch the comment entered in the textarea
           var comment = (document.getElementById('commentTextarea') as HTMLTextAreaElement)?.value;
           console.log(comment.length);
           
         comment=comment.trimStart()
 
           if (!comment || comment.length > 58) {
             Swal.showValidationMessage('Please enter a comment max 15 length');
             return false;
           }
           
           return { comment }; // Pass the comment for further use
         }
       }).then((result) => {
         if (result.isConfirmed) {
       result.value.comment=  result.value.comment.trimStart()
           let request={
             id:eachCase._id,
             disableReason:  result.value.comment?.toUpperCase()
           }
     
           this.loginService.updateActiveStatus(this.token,request)
           .subscribe((response: any) => {
           if (response&&response.status === 1) {
             Swal.fire("Success")
            //  this.listStatusCount()
 
         this.listCases()
     
     
            }else{
             Swal.fire(response.errorDetails)
            }
     
         })
      
      } })
     
       
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
     }
 
     getStatusClass(status: string): string {
      switch (status) {
        case 'New case':
          return 'status-new-case';
        case 'Under Validation':
          return 'status-under-validation';
        case 'Verified':
          return 'status-verified';
        case 'Under Telecall':
          return 'status-under-telecall';
        case 'Interested':
          return 'status-interested';
        case 'Not Interested':
          return 'status-not-interested';
        case 'Client Enrolled':
          return 'status-client-enrolled';
        case 'Field Visit':
          return 'status-field-visit';
        default:
          return 'status-default';
      }
    }
    
}
