
<body>
    <div class="main-wrapper">
        <div class="main-content">
            <header>
    
                <div class="head-line">
    
                    <p>Reminder</p>
                    
    
                </div>
                <div class="user-wrapper">
                    <p>{{storedData.agencyRefId.agencyName}}</p>
    
                    <div>
                        <img class="log1" [src]="this.storedData.agencyRefId.logo?this.storedData.agencyRefId.logo:'../../../../assets/../../assets/images/user1.jpeg'">
                    </div>
                </div>
            </header>
    
    
          
    
            <div class="header3">
    
                <div class="dropdown-container">
                    <label for="rows">Rows</label>
                    <div class="middle-b"></div>
                    <select id="rows"  (change)="onTableSizeChange($event)">
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="1000">1000</option>

                    </select>
                </div>
    
    
<!-- 
                <div class="dropdown-container">
                    <label for="rows">Initiated By</label>
                    <div class="middle-b"></div>
                    <select id="rows"  (change)="onInitiatedByChange($event)"   style="    width: 196px;" >
                        <option *ngFor="let item of staffDetails" [value]="item._id">{{item.name}}</option>
                        
                    </select>
                </div> -->



    
                <div class="fill-sea">
                    <div class="search-m">
                        <input type="text" id="searchQueryInput2" placeholder="Search here..."    [(ngModel)]="searchQuery"  (input)="listReminderCases()">
                        <button type="submit" id="searchQuerySubmit"><svg viewBox="0 0 24 24"
                                style="width: 24px; height: 24px;">
                                <path fill="#666666"
                                    d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z">
                                </path>
                            </svg>
                        </button>
                    </div>
                    <!-- <div class="filter-img-container"> -->
                        <!-- <img src="../../assets/images/filter_alt.png" class="filter-img"> -->
    <!-- <select>
        <option>1</option>
        <option>2</option>

    </select>
                    </div> -->
                </div>
               
    
            </div>
    
            <main class="main-2">
    
                <div class="container">
                    <div class="projects">
    
    
                        <div class="two-screen">
    
                            <div class="mainside2">
                                <table class="table-main">
                                    <thead>
    
    
                                        <tr>
                                            <!-- <th (click)="sortTable('clientRefId')" >Ref. ID <span *ngIf="sortField === 'clientRefId'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th> -->
                                            <th (click)="sortTable('createdAt')" style="width: 6%;">Initiated   date<span *ngIf="sortField === 'createdAt'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                            <th (click)="sortTable('initiatedBy.name')" style="width: 8%;">Initiated by<span *ngIf="sortField === 'initiatedBy.name'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                            <th (click)="sortTable('reminderSetBy.name')" style="width: 8%;">Reminder by<span *ngIf="sortField === 'reminderSetBy.name'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                            <th (click)="sortTable('reminder.reminderDate')" style="width: 6%;">Reminder Date<span *ngIf="sortField === 'reminder.reminderDate'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>

                                              <th  (click)="sortTable('reminder.reminderAllocatedTo.name')" style="width: 8%;">Reminder To<span *ngIf="sortField === 'reminder.reminderAllocatedTo.name'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                              <th  (click)="sortTable('reminder.reminderRemarks')" style="width: 25%;">Reminder Comment<span *ngIf="sortField === 'reminder.reminderTo'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>

                                            <th (click)="sortTable('clientName')"  style="width: 25%;">Company name<span *ngIf="sortField === 'clientName'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                            <th (click)="sortTable('updatedAt')"  style="width: 6%;">Updated date<span *ngIf="sortField === 'updatedAt'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
 <th  style="cursor: pointer;width:8%"  (click)="sortTable('updatedBy')">Updated by<span *ngIf="sortField === 'updatedBy'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>  
                                            <th class="med-width" (click)="sortTable('status')"  style="width: 6%;">Status<span *ngIf="sortField === 'status'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>


                                            <th class="right-align">Actions </th>
                                            <!-- <th class="right-align move-right"><img src="img/Ellipse 92.svg"></th> -->
                                        </tr>
                                    </thead>
                                     <tbody>
                                        <tr    *ngFor="let eachCase of this.reminderCases | paginate : pagingConfig; let i = index;  ">
                                            <!-- <td>
                                                <p class="name-one">{{eachCase.clientRefId}}</p>
    
                                            </td> -->
                                            <td>
                                                <p class="name-one">{{ format(eachCase.createdAt)}}</p>
                                            </td>
    
                                            <td>
                                                <p class="name-one">{{eachCase.initiatedBy.name}}</p>
                                            </td>

                                           
                                            <td>
                                                <p class="name-one">{{eachCase.reminder.reminderSetBy.name
                                                    }}</p>
                                            </td>
                                            <td>
                                                <p class="name-one">{{format(eachCase.reminder.reminderDate)
                                                    }}</p>
                                            </td>
                                            <td >
                                                <p class="name-one">{{eachCase.reminder.reminderAllocatedTo?eachCase.reminder.reminderAllocatedTo.name:''}}</p>
                                            </td>
                                            <td >
                                                <p class="name-one" style="width: 103px;">{{eachCase.reminder.reminderRemarks?eachCase.reminder.reminderRemarks:''}}</p>
                                            </td>
                                            <td>
                                                <p class="name-one">{{eachCase.clientName}}</p>
                                            </td>
                                            <td>
                                                <p class="name-one">{{ format(eachCase.updatedAt)}}</p>
                                            </td>

                                            <td >
                                                <p class="name-one">{{eachCase.updatedBy?eachCase.updatedBy.name:''}}</p>
                                            </td>
                                            <td><button class="status-one" [ngClass]="getStatusClass(eachCase.status)">{{eachCase.status}} <img
                                                        src="../../assets/images/green-circle.png"></button></td>
                                            <td class="right-align">
                                                <div class="pop-over">
                                                    <img src="../../assets/images/eye-open (1).png"   style="filter: invert(1) brightness(0);cursor: pointer;" 
                                                    (click)="ClientDetail('EDIT',eachCase)">&nbsp;
                                                    <a  class="dis-col" (click)="reset(eachCase)" style="cursor: pointer;">Reset</a>
 
                                                </div>
                                            </td>
                                        </tr> 
    
    
                                    
    
    
    
                                     
                                     </tbody> 
                                    

                                     </table>
                                     <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
                                    </pagination-controls>
                                    <span style="    margin-left: 76px;">{{currentCount }}  Of  {{ total}}</span>
                                     <!-- <span style="text-align: right;">{{currentCount }}  Of  {{ total}}</span> -->
                                     </div>
                                  
                          
    
    
                           
                        </div>
    
                    </div>
    
                </div>
    
    
            </main>
    
        </div>
    </div>
    
    <div class="footer-section2">
    
        <img src="../../assets/images/accruon-footer (2).png">
    </div>
    
    </body>
    
 
