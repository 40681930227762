
<body>
<div class="sidebar">
    <div class="sidebar-brand">
        <h2>
        <img src="../../assets/images/marketingApp-logo (1).png">
</h2>
    </div>
    <div class="sidebar-menu">
        <ul>
            <li>
                <a>
                 <div class="inactive-img" (click)="click='dashboard';route()"  [ngClass]="{ 'active' : click=='dashboard' }">
                    <div>
                        <img src="../../assets/images/dashboard.svg">
                         
                    </div>
                    <span>Dashboard</span>
                </div>
                </a>
            </li>
            <li>
                <a>
                 <div class="inactive-img"   (click)="click='staff';route()"  [ngClass]="{ 'active' : click=='staff' }">
                    <div><img src="../../assets/images/staff-list.svg" height="34px"></div>
                    <span>Staff List</span>
                </div>
                </a>
            </li>
            <li>
                <a>
                  <div class="inactive-img" (click)="click='products';route()"  [ngClass]="{ 'active' : click=='products' }">
                    <div><img src="../../assets/images/products.svg"></div>
                    <span>Products</span>
                   </div>
                </a>
            </li>
            <li>
                <a>
                  <div class="inactive-img" (click)="click='clients';route()"  [ngClass]="{ 'active' : click=='clients' }">
                    <div><img src="../../assets/images/clients.svg"></div>
                    <span>Clients</span>
                   </div>
                </a>
            </li>
            <li>
                <a>
                  <div class="inactive-img" (click)="click='report';route()"  [ngClass]="{ 'active' : click=='report' }">
                    <div><img src="../../assets/images/reports.svg"></div>
                    <span>Reports</span>
                   </div>
                </a>
            </li>
        </ul>


<div class="last-icon">
<div class="sec-one" (click)="profile()">
<img [src]="this.storedData.agencyRefId.logo?this.storedData.agencyRefId.logo:'../../../../assets/images/user1.jpeg'">
<div class="user-sec">
<p>{{this.storedData.name}}</p>
<span>Client Admin</span>
</div>
</div>
<div class="sec-two"  (click)="logOut()">
<div class="log-div"><img src="../../assets/images/logout.png"></div>
<p>Logout</p>
</div>
</div>


    </div>
</div>
</body>