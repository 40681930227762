import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-rejected-files',
  templateUrl: './rejected-files.component.html',
  styleUrls: ['./rejected-files.component.css']
})
export class RejectedFilesComponent implements OnInit {

  currentPage: number = 1;
   AllocationFlag:any=false
   itemsPerPage: number = 25;
   totalItems: number =0
   tableSize: number[] = [5, 10, 20, 50, 100];
   pagingConfig: any = {}
   pageNo: any;
   size: any;
   currentCount:any=0
   total:any=0
   searchQuery:any=''
   rejectedCases:any=[]
   token:any
   storedData:any
   constructor(private loginService:LoginService,private router:Router) { this.pagingConfig = {
     itemsPerPage: this.itemsPerPage,
     currentPage: this.currentPage,
     totalItems: this.totalItems
   } }
 
   ngOnInit(): void {
     this.token = localStorage.getItem('token');
     this.storedData= JSON.parse(localStorage.getItem("user") || '{}');
    this.listRejectedCases()
   }
   onTableSizeChange(event: any): void {
     this.pagingConfig.itemsPerPage = event.target.value;
     this.pagingConfig.currentPage = 1;
     this.size = event.target.value
     this.listRejectedCases()
 
   }
   onTableDataChange(event: any) {
     this.pagingConfig.currentPage = event;
     this.listRejectedCases()
   }
   listRejectedCases(){
     
 
     let req={
       searchKey:this.searchQuery,
 
       page:this.pagingConfig.currentPage,
       limit:this.pagingConfig.itemsPerPage
     }
 
 
    //  let body={
    //   status:"Not Interested",

    //    userType:this.storedData.userType[0].userType,
 
    //  }
 
 
       this.loginService.DisabledUserList(this.token,req)
                 .subscribe((response: any) => {
                   if (response.status === 1) {
                          this.rejectedCases=response.result
                          this.pagingConfig.totalItems = response.result.length;
                         this.currentCount= response.result.length
                          this. total=response.totalCount
                   }})
   }
   ClientDetail(flag:any,obj:any){
     obj.flag=flag
     localStorage.setItem('Work',JSON.stringify(obj))
     this.router.navigate(["/add-work-page"])
 
   }

   Inactive(data:any){
    Swal.fire({
      title: 'Disable File?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
  }).then((result:any) => {
      if (result.isConfirmed) {
        let req={
          id:data._id,
          // userName:data.userName,
          // isActive:'active'
        }
      
          this.loginService.disableStaff(this.token,req)
                  .subscribe((response: any) => {
                    if (response.status&&response.status === 1) {
                      Swal.fire("Success")
    
    this.listRejectedCases()
    
                    }
       })

      }})





   



   }
   formatDate(isoDate: string): string {
     if(isoDate){
       const date = new Date(isoDate);
       const day = String(date.getDate()).padStart(2, '0');
       const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
       const year = date.getFullYear();
       return `${day}/${month}/${year}`;
     }else{
       return ''
     }
   
   }
 

}
