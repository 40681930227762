<body>


    <div class="img-log">
        <img src="../../assets/images/log-marketingApp (1).png">
    </div>
    <div class="clean-login">
        
      <form>
       <div class="f-width">
        <h1 class="heading">
            Welcome..
        </h1><h2>Switch User</h2>
        <!-- <p>Please enter the <span>login</span> details</p> -->
      </div> 
      
        <form >

            <div class="form1">
                <div class="form-group" >
                  <label>User Types</label>
                <select style="width: 215px; height: 34px;"  [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedUserType"  >
                    <option *ngFor="let type of userTypeArray" [value]="type._id">
                      {{ type.userType}}
                    </option>
                  </select>
                  
                </div>
                </div>
<!--             
            <div class="header2">
                <div class="dropdown-container">
                    <label for="rows">UserType</label>

                    <div class="middle-b"></div>
                    <select id="rows"  style="width: 110px;">

                    <option value="Disabled">Disabled</option>
                    <option value="Not Interested">Rejected</option>
                    <option value="Client Enrolled">Client Enrolled</option>

                    
                </select>
                </div> 
                </div> -->

        <div class="btn-div">
            <button type="submit" class="btn-primary" (click)="SwitchUser()">Submit</button>
        </div>
          </form>
      
    
      <div class="poster">
        <div class="log">
            <img src="../../assets/images/log-login (1).png">
        </div>
      </div>
     </form>
    </div>
    
    
     <!--Popup start - Forgot password? -->
  
      
       
       <!--Popup end-->
    
     <!--Popup start -temporary password-->
    
   
       
       <!--Popup end-->
    
    
    <footer class="footer-main">
      <img src="../../assets/images/accruon-footer (2).png">
      <!-- Powered by <span>Accruon Technologies Pvt Ltd</span> -->
    </footer>
    
    
   
    
    
    
    
    </body>