import { Component, NgModule, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';

import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { LoginService } from '../login/login.service';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';


@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

  @ViewChild("agmSearch") public searchElement!: ElementRef;
  clientForm!: FormGroup
  disabled:any=false
  clientDetailsWithhistoryFlag:any=false
  clientDetailswithAuthorisedPersonFlag:any=false
  addClientFlag:any=false
  editClientFlag:any=false
  cientDetails:any=[]
  selectedClientId:any
  searchQuery = '';
  filteredClients:any=[]
  productList:any=[]
  token:any
  selectedClientDetails:any={}
  productArray:any=[]
  serviceFormats:any=[]
  // google:any
  latitude:any
  longitude:any
  address:any
  showMap:any = false;
  // zoom:any

  lat = 51.678418;
  lng = 7.809007;
  zoom = 10;
  private geoCoder: any;
 
  work_location: any;

  lat2:any;
  lng2:any
  lat1:any;
  dropdownSettings:any={}
  selectedItems:any=[]

  ShowFilter:any=false
  long1:any;
  storedData:any 
  constructor(private formBuilder: FormBuilder,private loginService:LoginService,private http: HttpClient,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {    
}

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
  this. storedData = JSON.parse(localStorage.getItem("user") || '{}');
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: this.ShowFilter
  };
    this.clientForm = this.formBuilder.group({
      clientName: ['', [Validators.required]],
      clientType: ['', [Validators.required,]],
      
      clientAddress:['',[Validators.required]],
      clientCountry:['',[Validators.required]],
      clientProductsList:['',[Validators.required]],
      clientBusinessDetails:['',[Validators.required]],
      clientLogo:['',[Validators.required]],
      clientBackground:[''],
      clientPhotos:['',[Validators.required]],
      clientGMap:['',[Validators.required]],
      clientOwnerDetails: this.formBuilder.array([]) ,

    

    })

    this.listClients()
    this.listProducts()
    this.latitude = 51.678418;
    this.longitude = 7.809007;
     this.zoom = 8;

     this.openMap()
  }
  listClients(){
    this.loginService.listClients(this.token)
    .subscribe((response: any) => {
      if (response.status === 1) {
              this.cientDetails =response.data
              this.selectedClientId=this.cientDetails[0]._id
              this.selectClient(this.cientDetails[0]._id)
         
  
      }
    })
  
  
    
  }
//   markerDragEndBranch($event: any) {
//     console.log($event);
//     console.log('lat', $event.latLng.lat()); //to see the latitude in the console
//     console.log('lng', $event.latLng.lng());
//     this.latitude = $event.latLng.lat();
//     this.longitude = $event.latLng.lng();








//    this.loginService.getAddress(this.latitude,this.longitude).subscribe(
//     (response) => {
//       if (response && response.address) {
//         // Construct the address string from the response
//         console.log(response.address);
        
//         this.address = `${response.address.road || ''}, ${response.address.city || ''}, ${response.address.country || ''}`;
//       } else {
//         this.address = 'Address not found';
//       }
//       console.log('Address:', this.address); // For debugging
   

//       this.clientForm.get('clientGMap')?.patchValue({
//         latitude:this.latitude,
//         longitude:this.longitude,
//         address:this.address
//       });

      

//     },
//     (error) => {
//       console.error('Error fetching address:', error);
//       this.address = 'Error fetching address'; // Error handling
//     }
//   );
// console.log( this.clientForm.value);

// this.google=false


   
//     // this.getAddress(this.latitude, this.longitude);
//   }



  listProducts(){

    this.loginService.listProducts(this.token)
    .subscribe((response: any) => {
      if (response.status === 1) {
              this.productList =response.data
              this.productList.map((each:any,index:any)=>{

                each.item_id=each._id
                each.item_text=each.name
             })
          
          this.serviceFormats=this.productList;
          console.log("gk",this.serviceFormats);

 
      }
    })
    
  }



  createClient(){
if(this.work_location){

    let clientGMap:any = {
      latitude: this.work_location.latitude,
      longitude: this.work_location.longitude,
      address: this.work_location.address,
      locationName: this.work_location.address
    }

    let arr = this.clientForm.value.clientProductsList.map((each: any) => each.item_id);
    this.clientForm.value.clientProductsList = arr;

    let submit_data:any = this.clientForm.value;
    submit_data.clientGMap = clientGMap;

    this.loginService.createClient(this.clientForm.value,this.token)
    .subscribe((response: any) => {
      if (response.status === 1) {
            Swal.fire("Success")
          this.listClients()
          this.addClientFlag=false
 
      }
    })
  }else{
    Swal.fire("Add Location")
  }
  }


  
  editClient(){

      this.editClientFlag=true

  } 

  updateClient(){

    let clientGMap:any = {
      latitude: this.work_location.latitude,
      longitude: this.work_location.longitude,
      address: this.work_location.address,
      locationName: this.work_location.address
    }
console.log(this.clientForm.value.clientProductsList);

    let arr = this.clientForm.value.clientProductsList.map((each: any) => each.item_id);
    this.clientForm.value.clientProductsList = arr;

    let submit_data:any = this.clientForm.value;
    submit_data.clientGMap = clientGMap;
    this.clientForm.value.clientId=this.selectedClientDetails._id
    this.loginService.updateClient(this.clientForm.value,this.token)
    .subscribe((response: any) => {
      if (response.status === 1) {
            Swal.fire("Success")
          this.listClients()
          this.editClientFlag=false

 this.selectedClientDetails=response.data
 this.clientDetailswithAuthorisedPersonFlag=true
 
      }
    })
  

      // update API Call
  }

  isSelected(id: any): boolean {
    // console.log("fgfh",id);
    
    localStorage.setItem("CurentClientId",id)
    return this.selectedClientId === id;
   
  }
 

  selectClient(id: any) {
    
    this.selectedClientId = id 
    // this.selectedAgencyDetails = this.agencyDetails.find((each: any) => {
    //   return each.id === this.selectedAgencyId;
    // });
    
 
            this.loginService.listSingleClient(this.token, this.selectedClientId)
            .subscribe((response: any) => {
              if (response.status === 1) {
                this.selectedClientDetails=response.data
    this.clientDetailswithAuthorisedPersonFlag=true
              
              }
              })

   console.log(this.selectedClientDetails);
   

    // this.addStaff=false
    // this.editStaff=false
  }


  upload(key:any,data:any){
    console.log(data,key);
    
        this.loginService.UploadMultiple(data.target.files, this.token)
        .subscribe((response: any) => {
            if (response.status === 1) {
    
              Swal.fire("Uploaded")
              if (key == "clientLogo") {
                // this.productForm.get('logo')?.patchValue(response.data);
                this.clientForm.get('clientLogo')?.patchValue(response.data[0]);

    
              }
              else if (key == "clientCoverImage"){

                this.clientForm.get('clientBackground')?.patchValue(response.data[0]);

                
              }
              else if(key=="clientPhotos"){
              // console.log("687686876",response.data.length);

                response.data.map((each:any)=>{
                 
                 this.productArray.push(each)

                })
                
                this.clientForm.get('clientPhotos')?.patchValue(this.productArray);
                    console.log(this.clientForm.value,this.productArray);


              }
            
           
            
            
            
            
            
            }})
    
    
    
    
    
    
      }
  




  get clientOwnerDetails() {
    return this.clientForm.get('clientOwnerDetails') as FormArray;
  }
  
  // Function to add a new security detail group
  addOwnerDetail() {
    const ownerDetail = this.formBuilder.group({
      Designation: ['', Validators.required],
      address: ['', Validators.required],
      whatsappNumber: ['', Validators.required],
      ownerName: ['', Validators.required],
      ownerEmail: ['', [Validators.required, Validators.email]],
      ownerPhone: ['', Validators.required]
    });
    this.clientOwnerDetails.push(ownerDetail);
    console.log(this.clientForm.value);
  }
  
  // Function to remove a security detail group
  removeOwnerDetail(index: number) {
    this.clientOwnerDetails.removeAt(index);
  }


  openMap() {
    


    console.log("HHHHHHHHHHHH JJJJJJJJJJJJJJJJJ", this.showMap,this.searchElement)
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      //   console.log(this.searchElementRef)
      setTimeout(() => {
        console.log("time outttttttttt",this.searchElement)
        let autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement);
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            console.log(place.geometry)

            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            if (place.geometry?.location) {
              this.longitude = place.geometry.location.lng();
              this.latitude = place.geometry.location.lat();
            }
            console.log(this.latitude, this.longitude)
            this.work_location = {
              latitude: this.latitude,
              longitude: this.longitude,
              address: place.formatted_address
            }

            console.log(this.work_location)
            this.zoom = 17;
          });
        });
      }, 5000);
    });

  
    // console.log(this.showMap)
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position)
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        console.log(this.latitude, this.longitude)
        if (this.work_location) {
          this.latitude = this.work_location.latitude;
          this.longitude = this.work_location.longitude;
        }
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude: any, longitude: any) {
    console.log(latitude, longitude)
    //var geocoder = new google.maps.Geocoder();
    // if (this.workOrder.work_location) {
    //   latitude = this.work_location.latitude;
    //   longitude = this.work_location.longitude;
    // }


    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 1;
          this.address = results[0].formatted_address;
          console.log(this.address)
          this.work_location = {
            latitude: latitude,
            longitude: longitude,
            address: results[0].formatted_address
          }

          this.clientForm.value.clientGMap={
            latitude: this.work_location.latitude,
            longitude: this.work_location.longitude,
            address: this.work_location.address,
            locationName: this.work_location.address
          }
      
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  markerDragEndBranch($event: any) {
    console.log($event);
    console.log('lat', $event.latLng.lat()); //to see the latitude in the console
    console.log('lng', $event.latLng.lng());
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
  }


  // setCoordinates(event: any, lng: any) {
  //   // this.latitude = event.target.value;
  //   // this.longitude = this.long1;
  //   // console.log(this.latitude,this.long1)
  //   this.getAddress(this.latitude, this.longitude);
  // }

  setLatitude(event: any,){
    this.latitude = parseFloat(event.target.value);
    this.getAddress(this.latitude, this.longitude);
  }

  setLongitude(event: any,){
    this.longitude = parseFloat(event.target.value);
    this.getAddress(this.latitude, this.longitude);
  }

  showMaps(){
    this.showMap = true;
    this.openMap();
  }

  ok(){
    this.clientForm.get('clientGMap')?.patchValue(this.work_location);

    // console.log(this.work_location)
    this.showMap = false;
  }


  setData(){
    console.log(this.selectedClientDetails);
    
   this.productArray=this.selectedClientDetails.clientPhotos
   this.selectedClientDetails.clientProductsList.map((each:any,index:any)=>{

    each.item_id=each._id
    each.item_text=each.name
 })

   this.clientForm.get('clientLogo')?.patchValue(this.selectedClientDetails.clientLogo);
   this.clientForm.get('clientBackground')?.patchValue(this.selectedClientDetails.clientBackground);
   this.clientForm.get('clientPhotos')?.patchValue(this.selectedClientDetails.clientPhotos);
   this.clientForm.get('clientName')?.patchValue(this.selectedClientDetails.clientName);
   this.clientForm.get('clientType')?.patchValue(this.selectedClientDetails.clientType);
   this.clientForm.get('clientAddress')?.patchValue(this.selectedClientDetails.clientAddress);
   this.clientForm.get('clientCountry')?.patchValue(this.selectedClientDetails.clientCountry);
   this.clientForm.get('clientProductsList')?.patchValue(this.selectedClientDetails.clientProductsList);
   this.clientForm.get('clientBusinessDetails')?.patchValue(this.selectedClientDetails.clientBusinessDetails);
   this.clientForm.get('clientGMap')?.patchValue(this.selectedClientDetails.clientGMap);


 this.work_location = {
              latitude: this.selectedClientDetails.clientGMap.latitude,
              longitude: this.selectedClientDetails.clientGMap.longitude,
              address: this.selectedClientDetails.clientGMap.address
            }




   
  //  this.clientForm.get('coverImage')?.patchValue(this.selectedProductfDetails.coverImage);
   const FormArray = this.clientForm.get('clientOwnerDetails') as FormArray;
  
    
   this.selectedClientDetails.clientOwnerDetails.forEach((detail: any) => {
     const securityDetailFormGroup = this.formBuilder.group({
      Designation: [detail.Designation, Validators.required],
      address: [detail.address, Validators.required],
      whatsappNumber: [detail.whatsappNumber, Validators.required],
      ownerName: [detail.ownerName, Validators.required],
      ownerEmail: [detail.ownerEmail, [Validators.required, Validators.email]],
      ownerPhone: [detail.ownerPhone, Validators.required]
     });
   
     FormArray.push(securityDetailFormGroup);
   });
   this.clientDetailswithAuthorisedPersonFlag=false
   this.editClientFlag=true
  }

}