<body>


    
    <div class="main-wrapper">
        <div class="main-content">
            <header>

                <div class="head-line">

                    <p>Reports</p>
                </div>
                <div class="user-wrapper">
                    <p>Skillplex Technologies</p>

                    <div>
                        <img class="log1" src="../../assets/images/Ellipse 2.png">
                    </div>
                </div>
            </header>

            <main class="main-2">

                <div class="container">
                    <div class="projects">


                        <div class="two-screen">
                            <!---sec sidebar-->

                            <div class="second-side">
                                <p class="table-heading">Please fill the details</p>

                                <form class="staff-form mt-2">
                                    <div class="row">
                                        <div class="col-lg-12 col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label>Select report*</label>
                                                <select class="select-al">
                                                  <option value="" selected="">Select your option</option>
                                                  <option value="">Coverage</option>
                                                  <option value="">Coverage</option>
                                                  <option value="">Coverage</option>
                                                 </select>
                                              </div>
                                    </div>
                                    <div class="col-lg-12 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                          <label>Date*</label>
                                          <input type="text" class="form-control" placeholder="Type here">
                                        </div>
                                        </div>
                                        <div class="col-lg-12 col-xs-12 col-sm-6">
                                            <div class="form-group">
                                              <label>Email ID*</label>
                                              <input type="text" class="form-control" placeholder="Type here">
                                            </div>
                                            </div>
                                            </div>
                                            </form>
                                            <div class="content mt-1">
                                                <button class="add-n">Add New Email ID</button>
                                                <button class="btn-two">Send</button>
                                            </div>


<!-- 
                                <div class="footer-section1">
                                    <button><img src="../../assets/images/plus-blue.png">Add New Staff</button>

                                </div> -->
                                <div class="footer-section2">

                                    <img src="../../assets/images/accruon-footer (2).png">
                                </div>

                            </div>



                            <!---------------->
                            <div class="card2">
                           

                            </div>
                        </div>

                    </div>

                </div>


            </main>

        </div>
    </div>


</body>